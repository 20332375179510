import React, { FC, ReactElement } from "react";
import {
  Dialog,
  DialogTitle,
  DialogProps,
  IconButton,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDialogStyles } from "./styles";

type SimpleDialogProps = {
  dialogId: string;
  dialogTitle: string;
  open: boolean;
  handleClose: () => void;
  dialogAlert?: ReactElement | boolean;
  dialogActions?: ReactElement;
};

const defaultProps: DialogProps = {
  maxWidth: "md",
  open: false,
};

const SimpleDialog: FC<SimpleDialogProps & DialogProps> = ({
  dialogId,
  dialogTitle,
  open,
  maxWidth,
  handleClose,
  children,
  dialogActions,
  dialogAlert,
}: SimpleDialogProps & DialogProps) => {
  const classes = useDialogStyles();

  const dialogTitleId = `${dialogId}-title`;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      id={dialogId}
      aria-labelledby={dialogTitleId}
      maxWidth={maxWidth}
      fullWidth
    >
      <DialogTitle
        disableTypography
        id={dialogTitleId}
        className={classes.dialogTitle}
      >
        <Typography
          variant="h3"
          component="h1"
          className={classes.dialogTitleText}
        >
          {dialogTitle}
        </Typography>
        <IconButton
          id="close-dialog-icon"
          aria-label="dialog-close-button"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
      {dialogAlert}
    </Dialog>
  );
};

SimpleDialog.defaultProps = defaultProps;
export default SimpleDialog;
