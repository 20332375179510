import { makeStyles } from "@material-ui/core";
import { deadCenter } from "../../styles/layouts";

export const useTableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    backgroundColor: theme.palette.background.default,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  noResult: {
    textAlign: "center",
    height: "400px",
    width: "100%",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgb(3, 115, 207, 0.1) !important",
    },
    "&:hover > td:first-child": {
      color: "#0373CF !important",
    },
  },
  tableRowText: {
    textTransform: "capitalize",
  },
  columnTitle: {
    margin: "0 10px 0 0",
  },
}));

export const useReportStyles = makeStyles((theme) => ({
  report: {
    padding: "24px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "flex-start",
    overflowX: "visible",
    paddingBottom: 24,
    maxWidth: 1000,
    margin: "0 auto",

    [theme.breakpoints.down("md")]: {
      maxWidth: "none",
      margin: 16,
    },

    "@media print": {
      display: "block",
      margin: "0 !important",
      padding: "0 !important",
      paddingTop: "24px !important",
    },
  },
  summaryReport: {
    "& h2": {
      // override the default details view section breaks
      pageBreakBefore: "auto !important",

      "&:nth-of-type(2)": {
        pageBreakBefore: "always !important",
      },
    },
  },
  spinnerContainer: {
    ...deadCenter,
    minHeight: "50vh",
  },
}));

export const useHeaderStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  headerActions: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "stretch",
    alignSelf: "stretch",
    flex: "1 1 auto",

    "& > :not(:last-child)": {
      marginRight: 8,
    },

    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
      "& > :not($headerItem)": {
        flex: "1 1 100%",
      },
      "& > :not(:last-child)": {
        marginRight: 0,
      },
      "& > :not($headerItem):not(:first-child)": {
        marginTop: 8,
      },
    },
  },
  headerItem: {
    padding: 16,
    backgroundColor: "rgba(172, 188, 207, 0.1)",
    borderRadius: 4,
    [theme.breakpoints.down("xs")]: {
      padding: 8,
      flex: "1 1 45%",
      "&:not(:last-of-type)": {
        marginRight: 4,
      },
      "&:not(:first-of-type)": {
        marginLeft: 4,
      },
    },
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  backButton: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: -56,
      marginRight: 8,
    },
  },
}));
