import { makeStyles } from "@material-ui/core";
import { standardBorderWithRadius } from "../../styles/borders";
import { deadCenter, splitPanel } from "../../styles/layouts";

export const useTipsPanelStyles = makeStyles((theme) => ({
  ...splitPanel(theme),
  headerContainer: {
    display: "flex",
    marginBottom: "16px",
  },
  tipsContainer: {
    ...standardBorderWithRadius,
    padding: "40px",
  },
  imageContainer: {
    ...deadCenter,
    padding: "40px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

export const useTipStyles = makeStyles({
  tip: {
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: "none",
    },
  },
});

export const useResourcePanelStyles = makeStyles({
  container: {
    ...standardBorderWithRadius,
    color: "inherit",
    height: "100%",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",

    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  panelItem: {
    marginBottom: "16px",

    "&:last-child": {
      marginBottom: "none",
    },
  },
  durationItem: {
    display: "flex",
    alignItems: "center",
    "&>svg": {
      marginRight: "8px",
    },
  },
  image: {
    height: "172px",
    width: "270px",
    objectFit: "cover",
  },
});

export const useCollapsibleGridStyles = makeStyles((theme) => ({
  collapsingHeaderContainer: {
    display: "flex",
    marginBottom: "16px",
  },
  hideShowButton: {
    border: "none",
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    fontSize: "16px",
    padding: "0 16px 0 16px",

    "&:hover": {
      cursor: "pointer",
    },
  },
}));
