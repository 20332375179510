import React, { FC } from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import { standardBorderWithRadius } from "../../styles/borders";
import { SurveyAnswerAndLabel } from "./IndividualDetail";
import { IndividualAnswer } from "./IndividualAnswer";
import { collapseOnPrint } from "../../styles/layouts";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "24px 0 16px 0",
    pageBreakAfter: "avoid",
    "@media print": {
      margin: "16px 0 8px 0",
    },
  },
  withImage: {
    ...standardBorderWithRadius,
    padding: 24,
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    "@media print": {
      flexDirection: "row !important",
      padding: 16,
    },
  },
  answerSide: {
    ...standardBorderWithRadius,
    padding: "40px 24px",
    "@media print": {
      padding: 16,
    },
  },
  image: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
      maxWidth: 250,
      alignSelf: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "265px",
      height: "265px",
      margin: "40px",
    },
    "@media print": {
      margin: 0,
      marginRight: 24,
      width: 250,
      height: 250,
    },
  },
  answers: {
    flex: "1 1 auto",
    [theme.breakpoints.down("xs")]: {
      marginTop: 24,
    },
  },
  answerWrapper: {
    ...collapseOnPrint,
  },
  answerSection: {
    pageBreakInside: "avoid",
  },
}));

type Prop = {
  imageUrl: string;
  title: string;
  answers: SurveyAnswerAndLabel[];
};

export const IndividualGroupedAnswers: FC<Prop> = ({
  imageUrl,
  title,
  answers,
}: Prop) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h3" component="h2" className={classes.title}>
        {title}
      </Typography>
      <Grid
        container
        justify="space-between"
        spacing={2}
        className={classes.answerWrapper}
      >
        <Grid item xs={12} md={8} className={classes.answerSection}>
          <div className={classes.withImage}>
            <img src={imageUrl} alt="" className={classes.image} />
            <div className={classes.answers}>
              {answers.length >= 2 &&
                answers.slice(0, 2).map((a) => {
                  return (
                    <IndividualAnswer
                      key={a.questionId}
                      questionLabel={a.questionLabel}
                      answer={a.answer as string[]}
                    />
                  );
                })}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} className={classes.answerSection}>
          <div className={classes.answerSide}>
            {answers.length >= 3 &&
              answers.slice(2, 3).map((a) => {
                return (
                  <IndividualAnswer
                    key={a.questionId}
                    questionLabel={a.questionLabel}
                    answer={a.answer as string[]}
                  />
                );
              })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
