import React, { FC, useState, useCallback, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { SurveyReport } from "@reachout/student-snapshot-api-types";
import cn from "classnames";

import TeacherLayout from "../../layout/TeacherLayout";
import ReportHeader from "../../components/Report/ReportHeader";
import ReportOverview from "../../components/Report/ReportOverview";
import ReportContent from "../../components/Report/ReportContent";
import { getReportDetails } from "../../services/surveyService";
import { useReportStyles } from "../../components/Report/styles";
import { track } from "../../services/heapService";

const GroupReport: FC = () => {
  const styles = useReportStyles();
  const [detailedView, setDetailedView] = useState(false);
  const params: Record<string, string> = useParams();
  const [report, setReport] = useState<SurveyReport | null>(null);

  // load the report
  useEffect(() => {
    getReportDetails(params.surveyId).then((report) => {
      setReport(report);
      track("View - Report", {
        responseCount: report.overview.responseCount,
        studentCount: report.overview.studentCount,
      });
    });
  }, [setReport, params]);

  const showDetailedView = useCallback(() => setDetailedView(true), [
    setDetailedView,
  ]);
  const hideDetailedView = useCallback(() => setDetailedView(false), [
    setDetailedView,
  ]);

  if (!report) {
    return (
      <TeacherLayout>
        <div className={styles.spinnerContainer}>
          <CircularProgress />
        </div>
      </TeacherLayout>
    );
  }

  return (
    <TeacherLayout>
      <div
        className={cn(styles.report, {
          // tweak print styles for summary to reduce whitespace
          [styles.summaryReport]: !detailedView,
        })}
      >
        <ReportHeader
          report={report}
          isDetailedView={detailedView}
          onShowDetailedView={showDetailedView}
          onHideDetailedView={hideDetailedView}
        />
        <ReportOverview overview={report.overview} />
        <ReportContent
          sections={report.sections}
          highlights={report.highlights}
          resources={report.resources}
          highlightsOnly={!detailedView}
          showDetails={showDetailedView}
        />
      </div>
    </TeacherLayout>
  );
};

export default GroupReport;
