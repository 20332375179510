import { makeStyles } from "@material-ui/core";
import { centeredColumn, deadCenter } from "../../../styles/layouts";

export const useRRStyles = makeStyles((theme) => ({
  pageContainer: {
    ...centeredColumn,
    marginTop: "16px",

    [theme.breakpoints.down("md")]: {
      padding: "0 16px 16px 16px",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0",
    },
  },
  spinnerContainer: {
    ...deadCenter,
    minHeight: "50vh",
  },
  headerContainer: {
    marginBottom: "38px",
  },
  collapsingHeaderContainer: {
    display: "flex",
    marginBottom: "16px",
  },
  relatedToContainer: {
    display: "flex",
    marginBottom: "16px",
  },
  relatedTo: {
    ...deadCenter,
    marginRight: "8px",
  },
  contentContainer: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "984px",
    },
    marginBottom: "16px",
  },
  hideShowButton: {
    border: "none",
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    fontSize: "16px",
    padding: "0 16px 0 16px",

    "&:hover": {
      cursor: "pointer",
    },
  },
}));
