import { History } from "history";

import { getSurveySummaries } from "./surveyService";
import { UserInfo } from "../Auth";

export const postLoginRedirect = async (
  user: UserInfo,
  history: History
): Promise<void> => {
  const surveys = await getSurveySummaries();
  // if user has surveys, jump to dashboard, otherwise show welcome screen
  if (surveys?.length) {
    history.push("/surveys");
  } else {
    history.push("/welcome");
  }
};
