import React, { FC } from "react";
import { Switch, Route } from "react-router-dom";

import Auth from "./Auth";

import TeacherApp from "./TeacherApp";
import StudentApp from "./StudentApp";

const App: FC = () => {
  return (
    <Switch>
      <Route path="/survey" component={StudentApp} />

      {/* Avoid rendering any of this if we're in a student page */}
      <Route>
        <Auth>
          <TeacherApp />
        </Auth>
      </Route>
    </Switch>
  );
};

export default App;
