import React, { FC, ReactElement, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { AuthContext } from "./Auth";
import { useQualtrics } from "./services/qualtricsService";

import NotFound from "./pages/NotFound";
import Login from "./pages/teacher/accounts/Login";
import Signup from "./pages/teacher/accounts/Signup";
import Welcome from "./pages/teacher/Welcome";

import SurveyDashboard from "./pages/teacher/SurveyDashboard";
import { CreateSurvey } from "./pages/teacher/CreateSurvey";

import IndividualReport from "./pages/teacher/IndividualReport";
import GroupReport from "./pages/teacher/GroupReport";
import IndividualDetailReport from "./pages/teacher/IndividualDetailReport";
import RecommendedResources from "./pages/teacher/recommendedResource";

/* eslint-disable @typescript-eslint/no-explicit-any */
const PrivateRoute = ({ component: Component, ...rest }: any): ReactElement => {
  const { isAuthenticated } = useContext(AuthContext);
  const routeComponent = (props: any): ReactElement =>
    isAuthenticated ? <Component {...props} /> : <Redirect to="/" />;
  return <Route {...rest} render={routeComponent} />;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

const TeacherApp: FC = () => {
  useQualtrics(); // start route listener for qualtrics survey intercept
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Switch>
      <Route exact path="/">
        {isAuthenticated ? (
          <Redirect to="/welcome" />
        ) : (
          <Redirect to="/login" />
        )}
      </Route>
      <PrivateRoute path="/welcome" component={Welcome} />
      <Route path="/surveys">
        <Switch>
          <PrivateRoute path="/surveys/create" component={CreateSurvey} />
          <PrivateRoute path="/surveys" component={SurveyDashboard} />
          <Route path="*">
            <Redirect to="/surveys" />
          </Route>
        </Switch>
      </Route>
      <Route path="/report">
        <Switch>
          <PrivateRoute
            path="/report/individual/:surveyId/:reponseId"
            component={IndividualDetailReport}
          />
          <PrivateRoute
            path="/report/individual/:surveyId"
            component={IndividualReport}
          />
          <PrivateRoute
            path="/report/group/:surveyId/resources"
            component={RecommendedResources}
          />
          <PrivateRoute
            path="/report/group/:surveyId"
            component={GroupReport}
          />
        </Switch>
      </Route>

      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default TeacherApp;
