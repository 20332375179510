import React, { FC } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import { Formik } from "formik";
import cn from "classnames";
import SurveyLayout from "../../layout/SurveyLayout";
import { StyledButton } from "../../components/StyledButton";

import SurveyQuestion from "../../components/SurveyQuestion";
import useStyles from "./Survey.styles";
import useSurveySubmission from "./useSurveySubmission";

const Survey: FC = () => {
  const s = useStyles();

  const {
    ready,
    validationSchema,
    initialValues,
    handleSubmit,
    surveyQuestions,
  } = useSurveySubmission();

  if (!ready) {
    return (
      <SurveyLayout>
        <div className={s.spinnerContainer}>
          <CircularProgress />
        </div>
      </SurveyLayout>
    );
  }

  const { templateId, questions, surveyName } = surveyQuestions!; // eslint-disable-line

  return (
    <SurveyLayout>
      <div className={s.container}>
        <div className={s.formContainer}>
          <Formik
            validateOnChange
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              setFieldTouched,
              handleSubmit,
              errors,
              touched,
            }): JSX.Element => (
              <form className={s.form} onSubmit={handleSubmit} id={templateId}>
                <Typography variant="h1">{surveyName}</Typography>

                <div className={s.allQuestionsContainer}>
                  {questions.map((question, idx) => {
                    const hasError = Boolean(
                      errors[question.id] && touched[question.id]
                    );
                    return (
                      <div
                        key={question.id}
                        className={cn(s.questionContainer, {
                          [s.hasRedBorder]: hasError,
                        })}
                      >
                        <Typography
                          component="h2"
                          variant="h4"
                          className={s.textContainer}
                          color={hasError ? "error" : "textPrimary"}
                        >
                          {idx + 1}. {question.text}
                        </Typography>

                        <SurveyQuestion
                          question={question}
                          values={values}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      </div>
                    );
                  })}
                </div>

                <div className={s.formActionsContainer}>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    text="Submit answers"
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </SurveyLayout>
  );
};

export default Survey;
