import React, { FC } from "react";
import { Link, makeStyles } from "@material-ui/core";
import cn from "classnames";
import { contentDividingBorder } from "../styles/borders";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    padding: "16px 24px",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      left: 0,
      bottom: 0,
    },
  },
  footerItem: {
    "&:not(:last-child)": {
      borderRight: contentDividingBorder(theme),
      paddingRight: 8,
      marginRight: 8,
    },
  },
}));

const Footer: FC = () => {
  const classes = useStyles();
  return (
    <div className={cn(classes.footerContainer, "hide-for-print")}>
      <Link
        id="footer-privacy-policy-link"
        className={classes.footerItem}
        href="https://au.reachout.com/our-policies/privacy-policy"
        target="_blank"
      >
        Privacy
      </Link>
      <Link
        id="footer-contact-us-link"
        className={classes.footerItem}
        href="https://schools.au.reachout.com/contact-us"
        target="_blank"
      >
        Contact us
      </Link>
    </div>
  );
};

export default Footer;
