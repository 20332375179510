import React, { FC, ReactNode } from "react";
import MuiCheckbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import {
  FormControlLabel,
  FormControl,
  FormHelperText,
  FormControlLabelProps,
} from "@material-ui/core";

export type Props = CheckboxProps & {
  id: string;
  label: ReactNode;
  labelPlacement?: FormControlLabelProps["labelPlacement"];
  fullWidth?: boolean;
  showError?: boolean;
  error?: string;
};

// Checkbox that doesn't connect to formik directly, used in multi choice component
const RawCheckbox: FC<Props> = ({
  label,
  className,
  showError,
  error,
  fullWidth = true,
  labelPlacement = "end",
  ...others
}) => {
  return (
    <FormControl error={showError} className={className} fullWidth={fullWidth}>
      <FormControlLabel
        label={label}
        labelPlacement={labelPlacement}
        control={<MuiCheckbox {...others} />}
      />
      {showError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default RawCheckbox;
