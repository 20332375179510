import React, { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link, makeStyles, Typography } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";

import { standardBorderWithRadius } from "../../styles/borders";
import SurveyLayout from "../../layout/SurveyLayout";
import { deadCenter } from "../../styles/layouts";
import { formFields, formField } from "../../styles/forms";
import { TextField, SingleSelect, Checkbox } from "../../components/Form";
import { StyledButton } from "../../components/StyledButton";
import { useSurveyResponseContext } from "../../state/student/surveyResponse";
import { useSurveyQuestionsContext } from "../../state/student/surveyQuestions";
import { SurveyPathParams } from "../../types";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      padding: "0 16px 16px 16px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0",
    },
  },
  formContainer: {
    ...deadCenter,
  },
  form: {
    ...standardBorderWithRadius,
    ...formFields,
    maxWidth: 483,
    padding: 24,
  },
  formField: {
    ...formField,
  },
  formDescription: {
    marginTop: 8,
    marginBottom: 16,
  },
  formInstruction: {
    marginBottom: 16,
  },
  buttonContainer: {
    padding: "14px 0",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginRight: "8px",
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

const detailsSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email address is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  gender: Yup.string().required("Gender is required"),
  agree: Yup.bool().oneOf([true], "Please accept to continue"),
});

const genderOptions = {
  male: "Male",
  female: "Female",
  nbgf: "Non binary/Gender fluid",
  different: "Different identity",
};

const StudentDetails: FC = () => {
  const s = useStyles();
  const h = useHistory();
  const { surveyId } = useParams<SurveyPathParams>();
  const {
    surveyResponseState,
    setSurveyResponseState,
  } = useSurveyResponseContext();
  const { surveyQuestions } = useSurveyQuestionsContext();

  // Redirect to welcome screen if not seen first
  if (!surveyQuestions) h.push(`/survey/${surveyId}/welcome`);

  return (
    <SurveyLayout>
      <div className={s.container}>
        <div className={s.formContainer}>
          <Formik
            validationSchema={detailsSchema}
            onSubmit={(values): void => {
              setSurveyResponseState({
                ...surveyResponseState,
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                gender: values.gender,
              });

              h.push(`/survey/${surveyId}`);
            }}
            initialValues={{
              email: "",
              firstName: "",
              lastName: "",
              gender: "",
              agree: false,
            }}
            initialErrors={{ email: "Email is required" }}
          >
            {({ handleSubmit, isValid }): JSX.Element => (
              <form className={s.form} onSubmit={handleSubmit}>
                <Typography variant="h1">Personal Details</Typography>
                <Typography color="textSecondary" className={s.formDescription}>
                  Since this survey is designed to help your teachers get to
                  know you, your name, gender and survey responses are recorded
                  and can be accessed by your teachers.
                </Typography>
                <Typography className={s.formInstruction}>
                  Enter the following details
                </Typography>

                <TextField
                  className={s.formField}
                  label="Email"
                  name="email"
                  type="email"
                />
                <TextField
                  className={s.formField}
                  label="First name"
                  name="firstName"
                />
                <TextField
                  className={s.formField}
                  label="Last name"
                  name="lastName"
                />
                <SingleSelect
                  className={s.formField}
                  label="Gender"
                  name="gender"
                  menuItems={genderOptions}
                />
                <Checkbox
                  className={s.formField}
                  name="agree"
                  label={
                    <>
                      I agree with ReachOut&apos;s{" "}
                      <Link
                        href="https://au.reachout.com/our-policies/terms-and-conditions"
                        target="_blank"
                      >
                        Terms & Conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        href="https://au.reachout.com/our-policies/privacy-policy"
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>
                    </>
                  }
                />

                <div className={s.buttonContainer}>
                  <StyledButton className={s.button} text="Cancel" />
                  <StyledButton
                    type="submit"
                    className={s.button}
                    text="Start survey"
                    variant="contained"
                    disabled={!isValid}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </SurveyLayout>
  );
};

export default StudentDetails;
