import React, { FC } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  stepper: {
    backgroundColor: "rgba(0, 0, 0, 0.26)",
    width: "8px",
    height: "8px",
    margin: "0 2px",
    borderRadius: "50%",
  },
  active: {
    backgroundColor: theme.palette.primary.dark,
  },
  stepperContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    padding: 5,
    borderRadius: 10,
  },
}));

type Props = {
  steps: number;
  activeStep: number;
};

const DotsStepper: FC<Props> = ({ steps, activeStep }: Props) => {
  const classes = useStyles();
  return (
    <>
      {steps > 1 && (
        <div className={classes.stepperContainer}>
          {[...Array(steps)].map((x, i) => (
            <div
              key={i}
              className={
                i + 1 === activeStep
                  ? `${classes.stepper} ${classes.active}`
                  : classes.stepper
              }
            />
          ))}
        </div>
      )}
    </>
  );
};

export default DotsStepper;
