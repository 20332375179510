import React, { FC, useState, useEffect } from "react";
import {
  CircularProgress,
  Collapse,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  RecommendedResource,
  SurveyReport,
} from "@reachout/student-snapshot-api-types";
import { useParams } from "react-router-dom";
import TeacherLayout from "../../../layout/TeacherLayout";
import { getReportDetails } from "../../../services/surveyService";
import cn from "classnames";
import { useRRStyles } from "./styles";
import TipsPanel from "../../../components/Resources/TipsPanel";
import CollapsibleGrid from "../../../components/Resources/CollapsibleGrid";
import { SurveyPathParams } from "../../../types";

const RecommendedResources: FC = () => {
  const { surveyId } = useParams<SurveyPathParams>();
  const s = useRRStyles();
  const [surveyReport, setSurveyReport] = useState<SurveyReport | null>(null);
  const [
    resourcesCollection,
    setResourcesCollection,
  ] = useState<RecommendedResource | null>(null);
  const [showTipsPanel, setShowTipsPanel] = useState<boolean>(true);

  useEffect(() => {
    if (!surveyReport) {
      getReportDetails(surveyId).then((surveyReport) => {
        setSurveyReport(surveyReport);
        setResourcesCollection(surveyReport.resources[0]);
      });
    }
  }, [surveyReport, setSurveyReport, surveyId]);

  const handleChangeCollection = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const targetCollection = surveyReport?.resources.find(
      (resource) => resource.title === event.target.value
    );
    if (targetCollection) {
      setResourcesCollection(targetCollection);
    }
  };

  if (!surveyReport) {
    return (
      <TeacherLayout>
        <div className={s.spinnerContainer}>
          <CircularProgress />
        </div>
      </TeacherLayout>
    );
  }

  return (
    <TeacherLayout>
      <div className={s.pageContainer}>
        <div className={cn(s.headerContainer, s.contentContainer)}>
          <Typography variant="h2">
            {surveyReport.overview.surveyName}
          </Typography>
        </div>

        <div className={cn(s.contentContainer, s.relatedToContainer)}>
          <Typography className={s.relatedTo}>Related to:</Typography>
          <FormControl variant="outlined">
            <Select
              onChange={handleChangeCollection}
              value={resourcesCollection?.title || ""}
            >
              {surveyReport.resources.map((r) => (
                <MenuItem key={r.title} value={r.title}>
                  {r.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={s.contentContainer}>
          <div className={s.collapsingHeaderContainer}>
            <Typography variant="h3">
              {resourcesCollection?.tips?.title ||
                "5 tips to support your students"}
            </Typography>
            <button
              className={s.hideShowButton}
              onClick={(): void => setShowTipsPanel(!showTipsPanel)}
            >
              {showTipsPanel ? "Hide" : "Show"}
            </button>
          </div>
          <Collapse in={showTipsPanel}>
            <TipsPanel tips={resourcesCollection?.tips?.points} />
          </Collapse>
        </div>

        {resourcesCollection?.teacherResources?.title && (
          <div className={s.contentContainer}>
            <CollapsibleGrid {...resourcesCollection.teacherResources} />
          </div>
        )}

        {resourcesCollection?.studentResources?.title && (
          <div className={s.contentContainer}>
            <CollapsibleGrid {...resourcesCollection.studentResources} />
          </div>
        )}

        {resourcesCollection?.parentResources?.title && (
          <div className={s.contentContainer}>
            <CollapsibleGrid {...resourcesCollection.parentResources} />
          </div>
        )}
      </div>
    </TeacherLayout>
  );
};

export default RecommendedResources;
