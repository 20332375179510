import { useEffect, useReducer, Reducer, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  QuestionType,
  SurveyQuestions,
  SurveyResponse,
} from "@reachout/student-snapshot-api-types";
import { submitSurvey } from "../../services/surveyService";
import {
  useSurveyResponseContext,
  isStudentValid,
} from "../../state/student/surveyResponse";
import { useSurveyQuestionsContext } from "../../state/student/surveyQuestions";
import { trackAnswers } from "../../services/heapService";
import { SurveyPathParams } from "../../types";

import { Action, ActionType, State } from "./Survey.types";
import { Values } from "../../components/SurveyQuestion/types";

import { FormikConfig } from "formik";

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_INITIAL_VALUES: {
      return { ...state, initialValues: action.payload };
    }
    case ActionType.SET_FORM_SCHEMA: {
      return { ...state, formSchema: action.payload };
    }
    case ActionType.SET_READY: {
      return { ...state, ready: action.payload };
    }
    default: {
      return state;
    }
  }
};

const QUESTION_MISSED_MESSAGE = "Compulstory question missed";
const QUESTION_RATING_MISSED_MESSAGE =
  "Please answer all parts of this question";

type SurveySubmissionProps = {
  surveyId: string;
  surveyQuestions: SurveyQuestions | null;
  handleSubmit: FormikConfig<Values>["onSubmit"];
  ready: boolean;
  validationSchema: Yup.ObjectSchema | null;
  initialValues: Values;
};

export default (): SurveySubmissionProps => {
  const h = useHistory();
  const { surveyId } = useParams<SurveyPathParams>();
  const { surveyQuestions } = useSurveyQuestionsContext();
  const {
    surveyResponseState,
    setSurveyResponseState,
  } = useSurveyResponseContext();
  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, {
    ready: false,
    formSchema: null,
    initialValues: {},
  });

  useEffect(() => {
    if (
      !surveyQuestions ||
      (surveyQuestions.isIdentified && !isStudentValid(surveyResponseState))
    ) {
      // If survey is identified, then the student needs to have
      // visited the student details page and we have subsequently captured
      // their information
      h.push(`/survey/${surveyId}/welcome`);
    }
  }, [h, surveyId, surveyQuestions, surveyResponseState]);

  useEffect(() => {
    if (
      !state.ready &&
      surveyQuestions &&
      (!surveyQuestions.isIdentified || isStudentValid(surveyResponseState))
    ) {
      // We don't know the shape of the survey questions, so once we have
      // them we need to build initial values and form schema to match
      // the structure of this survey.
      const initialValues = surveyQuestions.questions.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: [] }),
        {}
      );
      dispatch({ type: ActionType.SET_INITIAL_VALUES, payload: initialValues });

      const formSchema = Yup.object().shape(
        surveyQuestions.questions.reduce((acc, question) => {
          if (question.type === QuestionType.RATING) {
            return {
              ...acc,
              [question.id]: Yup.array()
                .min(question.ratings.length, QUESTION_RATING_MISSED_MESSAGE)
                .required(QUESTION_MISSED_MESSAGE),
            };
          } else if (question.type === QuestionType.MULTIPLE_CHOICE) {
            return {
              ...acc,
              [question.id]: Yup.array().min(1, QUESTION_MISSED_MESSAGE),
            };
          }

          return {
            ...acc,
            [question.id]: Yup.string().required(QUESTION_MISSED_MESSAGE),
          };
        }, {})
      );

      dispatch({ type: ActionType.SET_FORM_SCHEMA, payload: formSchema });
      dispatch({ type: ActionType.SET_READY, payload: true });
    }
  }, [
    h,
    state.ready,
    surveyResponseState,
    surveyId,
    surveyQuestions,
    setSurveyResponseState,
  ]);

  const handleSubmit = useCallback(
    async (values): Promise<void> => {
      const answers = Object.keys(values)
        .filter((k) => Boolean(values[k])) // only return non-null answers
        .map((k) => ({
          questionId: k,
          answer: values[k], // we know it's not null
        }));

      const responseStateWithAnswers = {
        ...surveyResponseState,
        answers,
      };

      const result = await submitSurvey(
        responseStateWithAnswers as SurveyResponse
      );

      setSurveyResponseState({
        ...surveyResponseState,
        answers,
      });

      if (result) {
        trackAnswers(surveyQuestions!.templateId || "", answers);
        h.push("/survey/success");
      } else {
        // Something went wrong submitting
      }
    },
    [h, surveyResponseState, setSurveyResponseState, surveyQuestions]
  );

  return {
    surveyId,
    handleSubmit,
    ready: state.ready,
    surveyQuestions: surveyQuestions,
    validationSchema: state.formSchema,
    initialValues: state.initialValues,
  };
};
