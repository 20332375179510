import React, { FC, useState } from "react";
import { makeStyles, Drawer, IconButton, Link } from "@material-ui/core";
import { Close as CloseIcon, Menu as MenuIcon } from "@material-ui/icons";

export type MenuItem = {
  label: string;
  href: string;
};

export type Props = {
  menuItems?: MenuItem[];
};

const deadCenter = {
  display: "flex",
  justifyContent: "center",
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    ...deadCenter,
    border: `1px solid ${theme.palette.primary.main}`,
    height: 44,
    width: 44,
    borderRadius: 7,
    fontSize: 34,
    fontWeight: 200,
    backgroundColor: "#FFFFFF",
    color: theme.palette.primary.main,
  },
  menuItem: {
    padding: "12px 24px",
    fontSize: 14,
    fontWeight: 600,
  },
  menuTitleContainer: {
    padding: 16,
    paddingLeft: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  drawerMenuContainer: {
    minWidth: 314,
    display: "flex",
    flexDirection: "column",
  },
  drawerMenuTitle: {
    ...deadCenter,
  },
}));

const HamburgerMenu: FC<Props> = ({ children, menuItems = [] }) => {
  const s = useStyles();
  const [isDrawerOpen, setDrawerState] = useState<boolean>(false);

  const setOpen = (): void => {
    setDrawerState(true);
  };

  const setClosed = (): void => {
    setDrawerState(false);
  };

  return (
    <>
      <IconButton
        id="hamburger-menu-open"
        className={s.buttonContainer}
        onClick={setOpen}
      >
        <MenuIcon />
      </IconButton>

      <Drawer anchor="right" open={isDrawerOpen} onClose={setClosed}>
        <div className={s.drawerMenuContainer}>
          <div className={s.menuTitleContainer}>
            <div className={s.drawerMenuTitle}>Menu</div>
            <IconButton
              id="hamburger-menu-close"
              role="menu"
              onClick={setClosed}
            >
              <CloseIcon />
            </IconButton>
          </div>

          {children ||
            menuItems.map((i) => (
              <Link
                key={i.label}
                role="menuitem"
                target="_blank"
                href={i.href}
                className={s.menuItem}
              >
                {i.label}
              </Link>
            ))}
        </div>
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
