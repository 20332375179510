import React, { FC } from "react";
import {
  QuestionType,
  RatingAnswer,
  AnyQuestionType,
} from "@reachout/student-snapshot-api-types";
import { FormikProps, FormikErrors, FormikTouched, FastField } from "formik";

import Rating from "./Rating";
import MultipleChoice from "./MultipleChoice";
import {
  FormValues,
  MultiQuestionProps,
  RatingQuestionProps,
  Values,
} from "./types";

type Props = {
  question: AnyQuestionType;
  values: Values;
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
  setFieldValue: FormikProps<Values>["setFieldValue"];
  setFieldTouched: FormikProps<Values>["setFieldTouched"];
};

const Question: FC<Props> = ({
  question,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
}) => {
  // only show errors if the field has been touched
  const hasError = Boolean(errors[question.id] && touched[question.id]);

  // if it's an unexpected question type, ignore it
  if (
    ![QuestionType.MULTIPLE_CHOICE, QuestionType.RATING].includes(question.type)
  ) {
    return null;
  }
  const isRating = question.type === QuestionType.RATING;

  return (
    <FastField name={question.id}>
      {({ form }: FormValues<string[] | RatingAnswer[]>): JSX.Element => {
        const questionProps = {
          error: hasError ? (errors[question.id] as string) : undefined,
          question,
          setFieldValue,
          setFieldTouched,
          value: form.values[question.id],
        };

        if (isRating) {
          return <Rating {...(questionProps as RatingQuestionProps)} />;
        } else {
          return <MultipleChoice {...(questionProps as MultiQuestionProps)} />;
        }
      }}
    </FastField>
  );
};

export default Question;
