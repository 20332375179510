import React, { FC } from "react";
import { QuestionInsight } from "@reachout/student-snapshot-api-types";
import { makeStyles, Typography } from "@material-ui/core";
import { Star as StarIcon } from "@material-ui/icons";
import {
  contentDividingBorder,
  standardBorderWithRadius,
} from "../../styles/borders";

type Props = {
  insight?: QuestionInsight;
};
const useStyles = makeStyles((theme) => ({
  insightWrapper: {
    ...standardBorderWithRadius,
    padding: 24,

    [theme.breakpoints.down("md")]: {
      padding: 16,
    },
    "@media print": {
      padding: 0,
      paddingTop: 16,
      border: "none",
    },
  },
  keyInsights: {
    borderTop: contentDividingBorder(theme),
    padding: "8px 0 0 0",
    paddingInlineStart: 0,
    listStyle: "none",
  },
  icon: {
    margin: "0 2px -6px -4px",
    padding: 2,
  },
}));
const ReportInsight: FC<Props> = ({ insight }) => {
  const styles = useStyles();

  if (!insight) return <div></div>;

  return (
    <div className={styles.insightWrapper}>
      <Typography variant="h5" component="h4" gutterBottom>
        <StarIcon className={styles.icon} />
        Summary
      </Typography>
      <Typography>{insight.title}</Typography>
      <ol className={styles.keyInsights}>
        {insight.keyItems.map((ki) => (
          <li key={ki.label}>
            <Typography>{ki.label}</Typography>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default ReportInsight;
