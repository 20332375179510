import { makeStyles } from "@material-ui/core";

export const useHeaderStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
  },

  logo: {
    height: 60,
    width: "auto",
    marginRight: 24,
  },

  menuRight: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    "& > :not(:last-child)": {
      marginRight: 8,
    },
  },

  menuItem: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },

  sectionDesktop: {
    display: "none",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      padding: "16px 24px",
    },
  },
  sectionMobile: {
    display: "flex",
    paddingTop: 8,
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  title: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
    textTransform: "capitalize",
  },
}));
