import React, { FC, useMemo } from "react";
import { Typography, makeStyles, IconButton } from "@material-ui/core";
import {
  SurveyResponse,
  SurveyResponseAnswer,
} from "@reachout/student-snapshot-api-types";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import cn from "classnames";

import characterImage from "../../assets/image/thumbs-up.svg";
import schoolLifeImage from "../../assets/image/school-life.svg";
import { IndividualGroupedAnswers } from "./IndividualGroupedAnswers";
import { IndividualPersonalInsight } from "./IndividualPersonalInsight";
import { DownloadButton } from "../StyledButton";
import { useHeaderStyles } from "./styles";

const useStyles = makeStyles((theme) => ({
  introSection: {
    padding: "20px 0 0 0",
    "@media print": {
      padding: 0,
    },
  },
  introDescription: {
    borderRadius: "4px",
    backgroundColor: "rgba(172, 188, 207, 0.1)",
    [theme.breakpoints.up("md")]: {
      margin: "20px 0",
      padding: "35px 40px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "5px 0",
      padding: "10px",
    },
    "@media print": {
      backgroundColor: "transparent",
      padding: 0,
      margin: 0,
      marginTop: 16,
    },
  },
}));

type Prop = {
  surveyResponse: SurveyResponse;
  surveyName: string;
  handleBackButtonClick: () => void;
};

export type QuestionLabel = {
  label: string;
  description?: string;
};

const questionLabels: Record<string, QuestionLabel> = {
  q1: { label: "Favourite subjects" },
  q2: { label: "General activities" },
  q3: { label: "Member of" },
  q4: { label: "Favourite classroom activities" },
  q5: {
    label: "School activities",
    description: "they want to be more involved in",
  },
  q6: { label: "Self-defined character strengths" },
  q7: { label: "Feels connected to" },
  q8: { label: "Cares about" },
  q9: { label: "Plans after school" },
  q10: { label: "Lives with" },
};

const capitalise = (text: string | undefined): string => {
  return text
    ? text
        .split(" ")
        .map((val) => val.charAt(0).toUpperCase() + val.slice(1))
        .join(" ")
    : "";
};

export interface SurveyAnswerAndLabel extends SurveyResponseAnswer {
  questionLabel: QuestionLabel;
}

const getAnswers = (
  response: SurveyResponse,
  questionIds: string[]
): SurveyAnswerAndLabel[] => {
  const result = new Array<SurveyAnswerAndLabel>();
  questionIds.forEach((questionId) => {
    const answer = response.answers.find((x) => x.questionId === questionId);
    if (answer) {
      result.push({
        ...answer,
        questionLabel: questionLabels[questionId],
      } as SurveyAnswerAndLabel);
    }
  });
  return result;
};

const IndividualDetail: FC<Prop> = ({
  surveyResponse,
  surveyName,
  handleBackButtonClick,
}: Prop) => {
  const classes = useStyles();
  const headerStyles = useHeaderStyles();
  const fullName = capitalise(
    `${surveyResponse.firstName} ${surveyResponse.lastName}`
  );

  const personalInsightAnswers = useMemo(
    () => getAnswers(surveyResponse, ["q10", "q2", "q7", "q3"]),
    [surveyResponse]
  );
  const characterAnswers = useMemo(
    () => getAnswers(surveyResponse, ["q8", "q9", "q6"]),
    [surveyResponse]
  );
  const schoolLifeAnswers = useMemo(
    () => getAnswers(surveyResponse, ["q1", "q4", "q5"]),
    [surveyResponse]
  );

  return (
    <>
      <div className={headerStyles.header}>
        <Typography variant="h2" component="h1" className={headerStyles.title}>
          <IconButton
            onClick={handleBackButtonClick}
            className={cn(headerStyles.backButton, "hide-for-print")}
            aria-label="Back to student response list"
          >
            <ArrowBackIcon />
          </IconButton>
          {surveyName} - {fullName}
        </Typography>
        <div className={cn(headerStyles.headerActions, "hide-for-print")}>
          <DownloadButton
            aria-label="Print report as PDF"
            onClick={(): void => window.print()}
          />
        </div>
      </div>
      <div className={classes.introSection}>
        <Typography variant="h3" component="h2">
          Introduction to individual snapshot
        </Typography>
        <div className={classes.introDescription}>
          <Typography paragraph>
            The purpose of this survey is to help you get to know your students,
            and build stronger relationships with them. Use the information to
            connect with your students and start positive conversations.
          </Typography>
        </div>
      </div>
      <IndividualPersonalInsight
        fullName={fullName}
        answers={personalInsightAnswers}
      />
      <IndividualGroupedAnswers
        title="Character"
        imageUrl={characterImage}
        answers={characterAnswers}
      />
      <IndividualGroupedAnswers
        title="School life"
        imageUrl={schoolLifeImage}
        answers={schoolLifeAnswers}
      />
    </>
  );
};

export default IndividualDetail;
