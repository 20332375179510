import React, { FC, useState, useCallback } from "react";
import { Typography, Snackbar } from "@material-ui/core";
import { StyledButton } from "../StyledButton";
import CopyToClipboard from "react-copy-to-clipboard";
import SimpleDialog from "../Dialog/SimpleDialog";
import { useSurveyLinkDialogStyles } from "./styles";
import cn from "classnames";

type SurveyLinkDialogProps = {
  open: boolean;
  handleClose: () => void;
  surveyLink: string;
  title: string;
};

export const SurveyLinkDialog: FC<SurveyLinkDialogProps> = ({
  open,
  handleClose,
  surveyLink,
  title,
}: SurveyLinkDialogProps) => {
  const styles = useSurveyLinkDialogStyles();
  const [isCopied, setIsCopied] = useState(false);
  const handeHideAlert = useCallback(() => setIsCopied(false), [setIsCopied]);

  return (
    <>
      <SimpleDialog
        dialogId="survey-link"
        dialogTitle={title}
        open={open}
        handleClose={handleClose}
        maxWidth="sm"
      >
        <Typography variant="body1" className={styles.surveyDescription}>
          Share this link with your students to get responses:
        </Typography>
        <div className={styles.surveyLinkContainer}>
          <Typography className={cn(styles.surveyLink, { copied: isCopied })}>
            {surveyLink}
          </Typography>
        </div>
        <div className={styles.buttonContainer}>
          <CopyToClipboard
            text={surveyLink}
            onCopy={(): void => setIsCopied(true)}
          >
            <StyledButton
              id="copy-link"
              text="Copy link"
              variant="contained"
              className={styles.surveyLinkButton}
            />
          </CopyToClipboard>
        </div>
      </SimpleDialog>
      <Snackbar
        open={isCopied}
        autoHideDuration={5000}
        onClose={handeHideAlert}
      >
        <Typography className={styles.alert}>Link copied</Typography>
      </Snackbar>
    </>
  );
};
