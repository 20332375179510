import React, { Component, ReactElement } from "react";
import TeacherLayout from "../../layout/TeacherLayout";
import { SurveyTemplate } from "@reachout/student-snapshot-api-types";
import { getSurveyTemplates } from "../../services/surveyService";
import { CreateSurveyComponent } from "../../components/CreateSurvey";

type State = {
  surveyTemplates: SurveyTemplate[];
};

export class CreateSurvey extends Component<{}, State> {
  mounted = false;

  constructor(props = {}) {
    super(props);
    this.state = {
      surveyTemplates: [] as SurveyTemplate[],
    };
  }

  async componentDidMount(): Promise<void> {
    this.mounted = true;
    const newTemplates = await getSurveyTemplates();
    if (this.mounted) {
      this.setState({ surveyTemplates: newTemplates || [] });
    }
  }

  componentWillUnmount(): void {
    // to avoid updating state on unmounted component, make this a no-op
    this.mounted = false;
  }

  render(): ReactElement {
    return (
      <TeacherLayout>
        <CreateSurveyComponent surveyTemplates={this.state.surveyTemplates} />
      </TeacherLayout>
    );
  }
}
