import * as Yup from "yup";

export const createSurveyFormValidationSchema = Yup.object().shape({
  surveyName: Yup.string().required("Survey name is required"),
  yearLevel: Yup.string().required("Year level is required"),
  studentCount: Yup.number()
    .typeError("Student count must be a number")
    .min(1, "Student count must be greater than 0")
    .required("Student count is required"),
});
