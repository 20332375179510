import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import cn from "classnames";
import teacherWithStudents from "../../assets/image/teacher-with-students.svg";
import { useTipStyles, useTipsPanelStyles } from "./styles";

const Tip: FC<{ num: number }> = ({ children, num }) => {
  const s = useTipStyles();

  return (
    <div className={s.tip}>
      <Typography color="textSecondary">Tip #{num}</Typography>
      <Typography>{children}</Typography>
    </div>
  );
};

type Props = {
  tips?: string[];
};

const TipsPanel: FC<Props> = ({ tips = [] }) => {
  const s = useTipsPanelStyles();

  return (
    <div className={cn(s.tipsContainer, s.splitPanel)}>
      <div className={s.panelItem}>
        {tips.map((tip, i) => (
          <Tip key={i} num={i + 1}>
            {tip}
          </Tip>
        ))}
      </div>

      <div className={cn(s.panelItem, s.imageContainer)}>
        <img
          alt="teacher with students"
          src={teacherWithStudents}
          className={s.image}
        />
      </div>
    </div>
  );
};

export default TipsPanel;
