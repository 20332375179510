import { makeStyles } from "@material-ui/core";

export const useDialogStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 24px 0px 24px",
  },
  dialogTitleText: {
    margin: "auto",
  },
}));
