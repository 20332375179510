import React, { ReactElement, useState, FC } from "react";
import { SurveyTemplate } from "@reachout/student-snapshot-api-types";
import { SurveyTemplateSelector } from "./SurveyTemplateSelector";
import { SurveyFormsCreator } from "./SurveyFormsCreator";
import { useHistory } from "react-router-dom";
import { useSurveyStyles } from "./styles";

type Props = {
  surveyTemplates: SurveyTemplate[];
};

export const CreateSurveyComponent: FC<Props> = ({
  surveyTemplates,
}: Props) => {
  const classes = useSurveyStyles();

  const [selected, setSelected] = useState(new Set<string>());
  const [currentStep, setCurrentStep] = useState(1);
  const history = useHistory();

  const handleNext = (): void => {
    setCurrentStep(currentStep + 1);
  };

  const handleCancel = (): void => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      history.push("/welcome");
    }
  };

  const handleSelect = (id: string): void => {
    selected.has(id) ? selected.delete(id) : selected.add(id);
    setSelected(new Set(selected));
  };

  const renderStep = (): ReactElement => {
    switch (currentStep) {
      case 2:
        return (
          <SurveyFormsCreator
            surveyTemplates={surveyTemplates}
            selected={selected}
            handleCancel={handleCancel}
            handleNext={handleNext}
          />
        );
      default:
        return (
          <SurveyTemplateSelector
            surveyTemplates={surveyTemplates}
            selected={selected}
            handleSelect={handleSelect}
            handleCancel={handleCancel}
            handleNext={handleNext}
          />
        );
    }
  };

  return <div className={classes.gridSection}>{renderStep()}</div>;
};
