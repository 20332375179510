import { Theme } from "@material-ui/core";

export const standardBorder = "1px solid rgba(136, 152, 170, 0.3)";

export const standardBorderWithRadius = {
  border: standardBorder,
  borderRadius: "4px",
};

export const contentDividingBorder = (theme: Theme) =>
  `1px solid ${theme.palette.grey[400]}`;
