import React, { FC } from "react";
import SurveyLayout from "../../layout/SurveyLayout";
import { makeStyles, Typography } from "@material-ui/core";
import { deadCenter } from "../../styles/layouts";
import highFive from "../../assets/image/high-five.svg";
import { standardBorderWithRadius } from "../../styles/borders";

const useStyles = makeStyles((theme) => ({
  container: {
    ...deadCenter,
    [theme.breakpoints.down("md")]: {
      padding: "0 16px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0",
    },
  },
  informationContainer: {
    width: "484px",
  },
  textContainer: {
    ...standardBorderWithRadius,
    padding: "24px 40px",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    position: "relative",
    zIndex: 1,
  },
  successMessage: {
    fontSize: 20,
  },
  imageContainer: {
    ...deadCenter,
    marginBottom: "-40px",
  },
  image: {
    maxWidth: "300px",
  },
}));

const Success: FC = () => {
  const s = useStyles();

  return (
    <SurveyLayout>
      <div className={s.container}>
        <div className={s.informationContainer}>
          <div className={s.imageContainer}>
            <img alt="students high five" src={highFive} className={s.image} />
          </div>

          <div className={s.textContainer}>
            <Typography variant="h1">All done!</Typography>
            <Typography className={s.successMessage} color="textSecondary">
              Thanks for completing the survey. If you have any questions about
              the survey, please speak to your teacher.
            </Typography>
          </div>
        </div>
      </div>
    </SurveyLayout>
  );
};

export default Success;
