import React, {
  useContext,
  FC,
  useState,
  useCallback,
  ReactElement,
  ChangeEvent,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import { Formik, Field, FieldProps, FormikProps } from "formik";
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
  Fade,
  Link as MuiLink,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import schoolAutocomplete from "./schoolAutocomplete.json";

import {
  termsAndConditionProps,
  privacyPolicyProps,
} from "../../../components/Link";
import { AuthContext } from "../../../Auth";
import { StyledButton } from "../../../components/StyledButton";
import TeacherLayout from "../../../layout/TeacherLayout";
import {
  TextField,
  PasswordField,
  SingleSelect,
  Autocomplete,
} from "../../../components/Form";

import useStyles from "./styles";
import { signupSchema } from "./validationSchemas";
import { positionOptions, SignupFormValues } from "./constants";

const initialValues = {
  firstName: "",
  email: "",
  password: "",
  isSchoolUser: "true",
  schoolName: "",
  organisationName: "",
  postcode: "",
  position: "",
  positionOther: "",
  recaptchaInput: "",
  recaptchaSiteKey: "",
};

declare global {
  interface Window {
    grecaptcha: {
      ready: (fn: any) => void;
      execute: (optWidgetId: string, action: object) => Promise<string>;
    };
  }
}

const RecaptchaErrorMessage: FC = () => (
  <>
    Uh-oh! You’ve been blocked for security reasons. If you think this is a
    mistake please contact us via email at{" "}
    <a href="mailto:contact@reachout.com">contact@reachout.com</a>
  </>
);

const Signup: FC = () => {
  const styles = useStyles();
  const [message, setMessage] = useState<{
    error: boolean;
    message: string | null;
    isRecaptchaError?: boolean;
  }>({
    error: false,
    message: null,
    isRecaptchaError: false,
  });
  const showSuccess = Boolean(message.message && !message.error);
  const { signup } = useContext(AuthContext);

  useEffect(() => {
    if (process.env.REACT_APP_RECAPTCHA_SCRIPT_URL) {
      const script = document.createElement("script");
      script.src = process.env.REACT_APP_RECAPTCHA_SCRIPT_URL;
      script.async = true;
      document.body.appendChild(script);
      return (): void => {
        document.body.removeChild(script);
      };
    }
  }, []); // eslint-disable-line

  const retrieveRecaptchaInput = (
    recaptchaSiteKey: string
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (typeof window.grecaptcha !== "undefined") {
        window.grecaptcha.ready(async () => {
          try {
            const token = await window.grecaptcha.execute(recaptchaSiteKey, {
              action: "submit",
            });
            resolve(token);
          } catch (ex) {
            console.error(
              `Failed to execute ecaptcha with site key: ${recaptchaSiteKey}`
            );
            reject();
          }
        });
      } else {
        reject();
      }
    });
  };

  const handleSignup = useCallback(
    async (values: SignupFormValues): Promise<void> => {
      const signupData = {
        ...values,
        isSchoolUser: values.isSchoolUser === "true",
      };

      // if the user changed the toggle after entering data, clear it out
      if (signupData.isSchoolUser) {
        delete signupData.organisationName;
      } else {
        delete signupData.schoolName;
      }

      if (signupData.position !== "Other") {
        delete signupData.positionOther;
      }

      const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
      if (recaptchaSiteKey) {
        signupData.recaptchaSiteKey = recaptchaSiteKey;
        try {
          signupData.recaptchaInput = await retrieveRecaptchaInput(
            recaptchaSiteKey
          );
        } catch {
          setMessage({
            error: true,
            message: "Failed to sign up. Try again later",
          });
          return;
        }
      }

      try {
        await signup(signupData);
        setMessage({
          error: false,
          message: "Please verify your email to continue",
        });
      } catch (ex) {
        setMessage({
          error: true,
          message: ex.message,
          isRecaptchaError: ex.message.match(/blocked for security reasons/),
        });
      }
    },
    [signup]
  );

  return (
    <TeacherLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={signupSchema}
        initialErrors={{ firstName: "Required" }}
        onSubmit={async (values, actions): Promise<void> => {
          await handleSignup(values);
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          setFieldTouched,
        }: FormikProps<typeof initialValues>): ReactElement => {
          const handleSchoolChange = (
            e: ChangeEvent<{}>,
            value: string | null
          ): void => {
            setFieldTouched("schoolName", true);
            setFieldValue("schoolName", value || "", true);
          };
          return (
            <form className={styles.root} onSubmit={handleSubmit}>
              <Typography className={styles.title} variant="h1">
                Create an account
              </Typography>

              <Collapse in={!showSuccess} timeout={1000}>
                <Alert severity="info" className={styles.explainer}>
                  <Typography>
                    Student Snapshot is powered by ReachOut Schools.
                    <br />
                    Signing up here will also provide access to the ReachOut
                    Schools website.
                  </Typography>
                </Alert>

                <div className={styles.formFields}>
                  <TextField
                    className={styles.formField}
                    label="First name"
                    name="firstName"
                  />
                  <TextField
                    className={styles.formField}
                    label="Email address"
                    name="email"
                    type="email"
                  />
                  <PasswordField
                    className={styles.formField}
                    label="Password"
                    name="password"
                    autoComplete="new-password"
                  />

                  <Typography id="is-school-user-label" gutterBottom>
                    Is your organisation a school?
                  </Typography>
                  <Field name="isSchoolUser">
                    {({
                      field,
                    }: FieldProps<Record<string, string>>): ReactElement => (
                      <RadioGroup
                        className={styles.formField}
                        aria-labelledby="is-school-user-label"
                        row
                        {...field}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  </Field>

                  {values.isSchoolUser === "true" ? (
                    <Autocomplete
                      label="School name"
                      error={errors["schoolName"]}
                      touched={Boolean(touched["schoolName"])}
                      value={values["schoolName"]}
                      onChange={handleSchoolChange}
                      options={schoolAutocomplete}
                      className={styles.formField}
                    />
                  ) : (
                    <>
                      <TextField
                        className={styles.formField}
                        label="Organisation name"
                        name="organisationName"
                      />
                      <TextField
                        className={styles.formField}
                        label="Postcode"
                        name="postcode"
                      />
                    </>
                  )}

                  <SingleSelect
                    className={styles.formField}
                    name="position"
                    label="Position"
                    menuItems={positionOptions}
                  />

                  {values.position === "Other" && (
                    <TextField
                      className={styles.formField}
                      label="Position name"
                      name="positionOther"
                    />
                  )}
                </div>

                {message.error && (
                  <Alert severity="error" className={styles.responseMessage}>
                    <Typography>
                      {message.isRecaptchaError ? (
                        <RecaptchaErrorMessage />
                      ) : (
                        message.message
                      )}
                    </Typography>
                  </Alert>
                )}

                <div className={styles.formActions}>
                  <div className={styles.alternateFlowAction}>
                    <span>Already have an account?</span>
                    <MuiLink
                      id="login-from-signup"
                      component={Link}
                      to="/login"
                    >
                      Log in now
                    </MuiLink>
                  </div>
                  <StyledButton
                    id="signup"
                    text="Sign up"
                    variant="contained"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  />
                </div>
                <Typography className={styles.agreeToTncs}>
                  By clicking this button you agree to ReachOut&apos;s{" "}
                  <MuiLink
                    component="a"
                    href={termsAndConditionProps.href}
                    target="_blank"
                  >
                    Terms of Service
                  </MuiLink>{" "}
                  &amp;{" "}
                  <MuiLink
                    component="a"
                    href={privacyPolicyProps.href}
                    target="_blank"
                  >
                    Privacy Policy
                  </MuiLink>
                </Typography>
                <Typography className={styles.recaptchaBranding}>
                  This site is protected by reCAPTCHA and the Google&nbsp;
                  <MuiLink
                    component="a"
                    href="https://policies.google.com/privacy"
                    target="_blank"
                  >
                    Privacy Policy
                  </MuiLink>{" "}
                  and&nbsp;
                  <MuiLink
                    component="a"
                    href="https://policies.google.com/terms"
                    target="_blank"
                  >
                    Terms of Service
                  </MuiLink>{" "}
                  apply.
                </Typography>
              </Collapse>

              <Fade in={showSuccess} timeout={1000}>
                <div className={styles.successMessage}>
                  <Typography variant="h4" component="p">
                    Thanks for signing up! <br />
                    Please check your inbox for a verification email to
                    continue.
                  </Typography>
                </div>
              </Fade>
            </form>
          );
        }}
      </Formik>
    </TeacherLayout>
  );
};

export default Signup;
