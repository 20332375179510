import React, { FC, useEffect, useState } from "react";
import { SurveySummary } from "@reachout/student-snapshot-api-types";
import { makeStyles, Button, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

import TeacherLayout from "../../layout/TeacherLayout";
import { getSurveySummaries } from "../../services/surveyService";
import { Heading } from "../../components/Headings";
import { SurveyListing } from "../../components/CreateSurvey/SurveyListing";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: 24,
    paddingBottom: 48,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 64, // allow space for FAB
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
  },
  editFab: {
    position: "fixed",
    right: 0,
    bottom: 0,
    margin: 8,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  createSurveyButton: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const SurveyDashboard: FC = () => {
  const classes = useStyles();
  const [surveys, setSurveys] = useState<SurveySummary[]>([]);

  useEffect((): (() => void) => {
    // flag to avoid warnings about setState on unmounted components
    let cancelled = false;

    getSurveySummaries().then((surveyList) => {
      if (!cancelled) setSurveys(surveyList || []);
    });

    return (): void => {
      cancelled = true;
    };
  }, [setSurveys]);

  return (
    <TeacherLayout>
      <div id="survey-dashboard" className={classes.rootContainer}>
        <div className={classes.titleContainer}>
          <Heading text="Survey dashboard" align="left" />
          <Button
            id="dashboard-create-survey"
            className={classes.createSurveyButton}
            variant="contained"
            color="secondary"
            component={Link}
            to="/surveys/create"
          >
            Create survey
          </Button>
        </div>
        <SurveyListing surveys={surveys} />

        <Fab
          color="secondary"
          aria-label="Create survey"
          id="dashboard-create-survey-mobile"
          className={classes.editFab}
          component={Link}
          to="/surveys/create"
        >
          <AddIcon />
        </Fab>
      </div>
    </TeacherLayout>
  );
};

export default SurveyDashboard;
