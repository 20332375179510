import React, { FC } from "react";
import { VictoryTooltip } from "victory";
import { ChartData } from "@reachout/student-snapshot-api-types";
import { makeStyles } from "@material-ui/core";

import Portal from "../../Portal";
import ChartLegend, { LegendData } from "./ChartLegend";

type ChartTooltipProps = {
  active?: boolean;
  x?: number;
  y?: number;
  datum?: ChartData;
  data: ChartData[];
  tooltipPortal: () => HTMLElement | null;
  colourScheme: string[];
  showAll?: boolean;
};

const useStyles = makeStyles({
  tooltip: {
    position: "absolute",
    padding: 8,
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.4)",
    background: "white",
    minWidth: 350,
    zIndex: 1,
  },
});

const ChartTooltip: FC<ChartTooltipProps> & { defaultEvents: any } = ({
  active,
  x,
  y,
  datum,
  tooltipPortal,
  colourScheme,
  data,
  showAll = false,
}) => {
  const styles = useStyles();
  // if no portal yet, just bail out
  const portalRoot = tooltipPortal();
  if (!portalRoot) return <g></g>;

  // datum is supplied by the victory chart, so it's always present. cast here to reduce redundant checks
  const chartData = datum as ChartData;

  const tooltipPosition: Record<string, string | number | undefined> = {
    left: x ? x + 20 : x,
    top: y ? y + 10 : y,
  };

  let entries: LegendData[];
  if (showAll) {
    entries = data.map((d, i) => ({
      inded: i,
      label: d.label,
      value: d.value,
    }));
  } else {
    // calculate the correct colours based on the original position in the data, regardless of whether we're only showing part
    entries = chartData.nested ? chartData.nested : [chartData];
    entries = entries.map((e) => ({
      ...e,
      index: data.findIndex((d) =>
        [e.label, chartData.label].includes(d.label)
      ),
    }));
  }

  return (
    <Portal root={portalRoot}>
      {active && (
        <ChartLegend
          includeHeaders
          className={styles.tooltip}
          style={tooltipPosition}
          data={entries}
          colourScheme={colourScheme}
        />
      )}
    </Portal>
    // </g>
  );
};

// eslint-disable-next-line
// @ts-ignore - bad typings don't include the static 'defaultEvents' object
ChartTooltip.defaultEvents = VictoryTooltip.defaultEvents;

export default ChartTooltip;
