import React, { FC, useMemo } from "react";
import {
  ReportQuestion,
  ChartData,
} from "@reachout/student-snapshot-api-types";
import { makeStyles, Typography } from "@material-ui/core";

import { defaultColourScheme, ratingColourScheme } from "./chartHelpers";
import ChartLegend from "./ChartLegend";
import ChartStackbar from "./ChartStackbar";

type Props = {
  chart: ReportQuestion;
  ratings?: boolean;
  barSize?: number;
};

const useStyles = makeStyles({
  chart: {
    width: "100%",
    height: "auto",
    overflowX: "visible",
  },
});

const ReportChartStackbar: FC<Props> = ({ chart, ratings = false }) => {
  const colourScheme = ratings ? ratingColourScheme : defaultColourScheme;

  // basically flattening the chart data so 'Other' becomes a list of whatever was nested in it again
  const legendData = useMemo(() => {
    return chart.chartData.reduce(
      (agg, cur) => [...agg, ...(cur.nested ? cur.nested : [cur])],
      [] as ChartData[]
    );
  }, [chart.chartData]);

  const styles = useStyles();

  return (
    <div className={styles.chart}>
      <Typography variant="h4" component="h3" gutterBottom>
        {chart.chartTitle}
      </Typography>
      <ChartStackbar
        chartData={chart.chartData}
        key={chart.chartTitle}
        colourScheme={colourScheme}
        barSize={40}
      />
      <ChartLegend data={legendData} colourScheme={colourScheme} />
    </div>
  );
};

export default ReportChartStackbar;
