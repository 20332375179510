import React, { FC, useState } from "react";
import { useSurveyStyles } from "./styles";
import {
  SurveyTemplate,
  SurveyData,
} from "@reachout/student-snapshot-api-types";
import { SurveyForm, CreateSurveyForm, HandleSubmitProps } from "./SurveyForm";
import { createSurvey } from "../../services/surveyService";
import { CenteredHeading } from "../Headings";
import { useHistory } from "react-router-dom";
import { track } from "../../services/heapService";

type Props = {
  surveyTemplates: SurveyTemplate[];
  selected: Set<string>;
  handleCancel: () => void;
  handleNext: () => void;
};

enum ButtonState {
  Next = "Next",
  Save = "Save",
}

export const SurveyFormsCreator: FC<Props> = ({
  surveyTemplates,
  selected,
  handleCancel,
}: Props) => {
  const classes = useSurveyStyles();
  const [currentStep, setCurrentStep] = useState(1);
  const [surveyData, setSurveyData] = useState(
    {} as { [key: string]: CreateSurveyForm }
  );
  const history = useHistory();

  const selectedTemplateId = Array.from(selected)[currentStep - 1];
  const selectedTemplate = surveyTemplates.find(
    (template) => template.templateId === selectedTemplateId
  );
  const currentTemplate = selectedTemplate
    ? selectedTemplate
    : ({} as SurveyTemplate);

  const handleStep2Cancel = (): void => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      handleCancel();
    }
  };

  const buttonText =
    currentStep === selected.size ? ButtonState.Save : ButtonState.Next;

  const nextHandler = ({ templateId, values }: HandleSubmitProps): void => {
    surveyData[templateId] = values;
    setCurrentStep(currentStep + 1);
    setSurveyData(surveyData);
  };

  const saveHandler = async ({
    templateId,
    values,
    actions,
  }: HandleSubmitProps): Promise<void> => {
    surveyData[templateId] = values;
    try {
      const createAllSurveys = Promise.all(
        Object.entries(surveyData).map(async ([templateId, formValues]) => {
          const survey = {
            surveyName: formValues.surveyName,
            yearLevel: formValues.yearLevel,
            studentCount: parseInt(formValues.studentCount),
            templateId,
          } as SurveyData;
          const result = await createSurvey(survey);
          if (!result) {
            return survey.surveyName;
          } else {
            track("Created - Survey", {
              templateId: survey.templateId,
              surveyId: result.surveyId,
            });
          }
        })
      );
      const failedSurveys = (await createAllSurveys).filter(Boolean);
      if (failedSurveys.length === 0) {
        history.push("/surveys");
      } else {
        actions.setStatus({
          showAlert: true,
          alertVariant: "error",
          alertMessage: `${
            failedSurveys.length !== 1 ? "Surveys" : "Survey"
          } ${failedSurveys.join(", ")} failed to create. Please try again.`,
        });
      }
    } catch (e) {
      actions.setStatus({
        showAlert: true,
        alertVariant: "error",
        alertMessage:
          "An error occurred while creating survey. Please try again.",
      });
      console.error("Failed to submit", e);
    }
  };

  const getFormValues = (id: string): CreateSurveyForm => {
    return (
      surveyData[id] ||
      ({
        surveyName: "",
        yearLevel: "Year 7",
        studentCount: "",
      } as CreateSurveyForm)
    );
  };

  return (
    <>
      {currentTemplate && (
        <>
          <div className={classes.surveyStep2Title}>
            <CenteredHeading text={`Survey type: ${currentTemplate.title}`} />
          </div>
          <SurveyForm
            handleCancel={handleStep2Cancel}
            handleSubmit={async (props: HandleSubmitProps): Promise<void> => {
              currentStep < selected.size
                ? nextHandler(props)
                : await saveHandler(props);
            }}
            buttonText={buttonText}
            initialValues={getFormValues(currentTemplate.templateId)}
            template={currentTemplate}
            steps={selected.size}
            currentStep={currentStep}
          ></SurveyForm>
        </>
      )}
    </>
  );
};
