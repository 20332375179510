import React, { FC, useEffect, useState } from "react";
import TeacherLayout from "../../layout/TeacherLayout";
import IndividualDetail from "../../components/Report/IndividualDetail";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { SurveyResponse } from "@reachout/student-snapshot-api-types";
import { useReportStyles } from "../../components/Report/styles";

interface RouteParams {
  surveyId: string;
}

export type IndividualDetailState = {
  surveyResponse: SurveyResponse;
  surveyName: string;
};

type Prop = RouteComponentProps<RouteParams, {}, IndividualDetailState>;

const IndividualDetailReport: FC<Prop> = ({ match, location }: Prop) => {
  const classes = useReportStyles();
  const history = useHistory();
  const [individualDetailState, setIndividualDetailState] = useState<
    IndividualDetailState
  >();

  useEffect(() => {
    if (location?.state) {
      setIndividualDetailState(location?.state);
    } else {
      history.push(`/report/individual/${match.params.surveyId}`);
    }
  }, [location, match.params.surveyId, history]);

  const handleBackButtonClick = (): void => {
    history.push(`/report/individual/${match.params.surveyId}`);
  };

  return (
    <TeacherLayout>
      <div className={classes.report}>
        {individualDetailState && (
          <IndividualDetail
            surveyResponse={individualDetailState.surveyResponse}
            surveyName={individualDetailState.surveyName}
            handleBackButtonClick={handleBackButtonClick}
          ></IndividualDetail>
        )}
      </div>
    </TeacherLayout>
  );
};

export default IndividualDetailReport;
