import React, { FC } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  dotContainer: {
    display: "inline-flex",
    padding: 4,
    marginRight: 4,
  },
  dot: {
    "&&": {
      width: 10,
      height: "10px !important",
    },
  },
});

type Props = {
  colour: string;
};

const ChartDot: FC<Props> = ({ colour }) => {
  const styles = useStyles({ colour });
  return (
    <span className={styles.dotContainer}>
      <svg className={styles.dot} viewBox="0 0 10 10">
        <circle cx={5} cy={5} r={5} fill={colour} />
      </svg>
    </span>
  );
};

export default ChartDot;
