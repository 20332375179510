import React, { FC, Dispatch } from "react";
import { FormControl, FormLabel, Typography, Grid } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import {
  RatingSubQuestion,
  RatingAnswer,
} from "@reachout/student-snapshot-api-types";
import cn from "classnames";

import { useSubRatingStyles } from "./styles";
import { Action } from "./index";

type SubRatingProps = RatingSubQuestion & {
  value: RatingAnswer[] | null;
  dispatch: Dispatch<Action>;
  promptContainerClass: string;
};

const SubRating: FC<SubRatingProps> = ({
  id,
  text,
  answerOptions,
  value,
  dispatch,
  promptContainerClass = "",
}) => {
  const s = useSubRatingStyles();
  const handleClick = (clickedValue: number): void => {
    dispatch({ type: "SET_SUBRATING", payload: { id, value: clickedValue } });
  };
  const isSelected = (targetValue: number): boolean => {
    return Boolean(value?.find((v) => v.id === id && v.value === targetValue));
  };

  return (
    <FormControl component="fieldset" className={s.fieldsetContainer}>
      <FormLabel
        component="legend"
        className={`${s.labelContainer} ${promptContainerClass}`}
      >
        {text}
      </FormLabel>
      <ToggleButtonGroup exclusive>
        <Grid container spacing={2}>
          {answerOptions.map((ao) => (
            // below grid uses "auto layout" for sm up to evenly space the rating items
            <Grid key={ao.value} item xs={12} sm>
              <label className={s.buttonAndLabelContainer}>
                <ToggleButton
                  id={`${id}-${ao.value}`}
                  className={cn(s.ratingButton, {
                    [s.ratingButtonSelected]: isSelected(ao.value),
                  })}
                  value={ao.value}
                  aria-label={ao.label}
                  onClick={(): void => {
                    handleClick(ao.value);
                  }}
                >
                  {ao.value}
                </ToggleButton>
                {!ao.hideLabel && (
                  <Typography className={s.label}>{ao.label}</Typography>
                )}
              </label>
            </Grid>
          ))}
        </Grid>
      </ToggleButtonGroup>
    </FormControl>
  );
};

export default SubRating;
