import React, { FC } from "react";
import { useSurveyStyles } from "./styles";
import { Button, Grid } from "@material-ui/core";
import { SurveyTemplate } from "@reachout/student-snapshot-api-types";
import { SurveyTemplateItem } from "./SurveyTemplateItem";
import { StyledButton } from "../StyledButton";
import { CenteredHeading } from "../Headings";
import { ExternalLink } from "../Link";

type Props = {
  surveyTemplates: SurveyTemplate[];
  selected: Set<string>;
  handleSelect: (templateId: string) => void;
  handleCancel: () => void;
  handleNext: () => void;
};

export const SurveyTemplateSelector: FC<Props> = ({
  surveyTemplates,
  selected,
  handleSelect,
  handleNext,
  handleCancel,
}: Props) => {
  const classes = useSurveyStyles();

  return (
    <div>
      <div className={classes.surveyStep1Title}>
        <CenteredHeading text="Choose a survey to send to your students" />
      </div>
      <Grid container spacing={2} className={classes.surveyTemplatesContainer}>
        {surveyTemplates.map((template) => (
          <Grid
            item
            xs={12}
            sm={6}
            key={template.templateId}
            className={classes.surveyTemplateItem}
          >
            <div className={classes.templateButton}>
              <Button
                id={`select-template-${template.templateId}`}
                fullWidth
                variant="outlined"
                onClick={(): void => handleSelect(template.templateId)}
                className={
                  selected.has(template.templateId)
                    ? classes.selectedTemplateButton
                    : undefined
                }
              >
                <SurveyTemplateItem template={template} />
              </Button>
              <ExternalLink
                className={classes.exampleLink}
                href={template.exampleUrl}
                label="See questions and sample report"
              />
            </div>
          </Grid>
        ))}
      </Grid>
      <div className={classes.surveyStep1ContainerActions}>
        <div className={classes.actions}>
          <StyledButton
            id="template-selector-cancel"
            text="Back"
            onClick={handleCancel}
          />
          <StyledButton
            id="template-selector-next"
            variant="contained"
            text="Next"
            onClick={handleNext}
            disabled={selected.size === 0}
          />
        </div>
      </div>
    </div>
  );
};
