import React, { Dispatch, FC, SetStateAction } from "react";
import { TimerOutlined as TimerIcon } from "@material-ui/icons";
import { Grid, Link, Typography } from "@material-ui/core";
import { Resource } from "@reachout/student-snapshot-api-types";
import cn from "classnames";
import { useResourcePanelStyles } from "./styles";

type Props = {
  resources: Resource[];
  setHeight: Dispatch<SetStateAction<number>>;
};

const ResourcePanel: FC<
  Resource & { setHeight?: Dispatch<SetStateAction<number>> }
> = ({ title, description, duration, imageUrl, resourceUrl, setHeight }) => {
  const s = useResourcePanelStyles();

  return (
    <Grid item xs={12} md={4}>
      <Link
        className={s.container}
        ref={(newRef): void => {
          setHeight && setHeight(newRef ? newRef.clientHeight + 5 : 20);
        }}
        href={resourceUrl}
        target="_blank"
      >
        <div className={s.panelItem}>
          <img className={s.image} alt="resource" src={imageUrl} />
        </div>

        <Typography className={s.panelItem} variant="h4" color="primary">
          {title}
        </Typography>

        {duration && (
          <Typography
            className={cn(s.panelItem, s.durationItem)}
            color="textSecondary"
          >
            <TimerIcon /> {duration}
          </Typography>
        )}

        <Typography className={s.panelItem}>{description}</Typography>
      </Link>
    </Grid>
  );
};

const ResourcesGrid: FC<Props> = ({ resources, setHeight }) => (
  <Grid container spacing={2}>
    {resources.map((resource, i) =>
      i === 0 ? (
        <ResourcePanel
          setHeight={setHeight}
          key={resource.title}
          {...resource}
        />
      ) : (
        <ResourcePanel key={resource.title} {...resource} />
      )
    )}
  </Grid>
);

export default ResourcesGrid;
