import React, { FC, createRef, useCallback, useMemo, useState } from "react";
import { ChartData } from "@reachout/student-snapshot-api-types";
import { VictoryStack, VictoryBar } from "victory";
import { makeStyles, Theme } from "@material-ui/core";

import {
  tooltipFollowingMouse,
  getCornerRadius,
  defaultColourScheme,
} from "./chartHelpers";
import ChartTooltip from "./ChartTooltip";

type Props = {
  chartData: ChartData[];
  barSize: number;
  ratings?: boolean;
  colourScheme?: string[];
  showAllInTooltip?: boolean;
};

interface StyleProps {
  chartHeight: number;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  tooltipContainer: {
    position: "absolute",
  },
  fixedHeightChart: {
    "&& svg": {
      height: ({ chartHeight }) => `${chartHeight + 10}px !important`,
    },
  },
}));

const ChartStackbar: FC<Props> = ({
  chartData,
  barSize,
  colourScheme = defaultColourScheme,
  showAllInTooltip = false,
}) => {
  const tooltipPortalRef = createRef<HTMLDivElement>();

  const styles = useStyles({ chartHeight: barSize } as StyleProps);

  const [width, setWidth] = useState(1); // needs to be non-zero, but recalculated immediately
  const measure = useCallback(
    (node: HTMLElement | null) => {
      if (!node) return;
      const chartWidth = node.getBoundingClientRect().width;

      setWidth(chartWidth);
    },
    [setWidth]
  );

  const getPortal = useCallback(() => tooltipPortalRef.current, [
    tooltipPortalRef,
  ]);

  // pre-calculate corners to avoid nested loops in every render
  const chartCorners = useMemo(
    () => chartData.map((_, i) => getCornerRadius(i, chartData, barSize, true)),
    [chartData, barSize]
  );

  return (
    <div className="stackbar-chart-wrapper" ref={measure}>
      <div className={styles.tooltipContainer} ref={tooltipPortalRef} />
      {/* IE11 compat issue - if we don't wrap the chart it expands to have random whitespace  */}
      <div className={styles.fixedHeightChart}>
        <VictoryStack
          horizontal
          colorScale={colourScheme}
          // width and height just specify the aspect ratio. it's still responsive
          width={width}
          height={barSize}
          padding={{
            top: barSize,
            bottom: barSize,
          }}
          domain={{
            x: [0, 1],
            y: [0, 100],
          }}
          style={{
            data: {
              stroke: "white",
              strokeWidth: 2,
              opacity: ({ active }): number => (active ? 0.85 : 1),
            },
          }}
        >
          {chartData.map((cd, i) => {
            return (
              <VictoryBar
                key={cd.label}
                data={[cd]}
                x="label"
                y="value"
                alignment="start"
                barWidth={barSize}
                cornerRadius={chartCorners[i]}
                labelComponent={
                  <ChartTooltip
                    tooltipPortal={getPortal}
                    colourScheme={colourScheme}
                    data={chartData}
                    showAll={showAllInTooltip}
                  />
                }
                events={[tooltipFollowingMouse]}
              />
            );
          })}
        </VictoryStack>
      </div>
    </div>
  );
};

export default ChartStackbar;
