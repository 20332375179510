import { StyleRules } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export const formField: StyleRules<string> = {
  "&:not(:last-child)": {
    marginBottom: 16,
  },
};

export const formFields: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "flex-start",
};
