import React, { FC, useMemo } from "react";
import {
  SurveyResponse,
  SurveyReport,
  SurveyWithResponses,
} from "@reachout/student-snapshot-api-types";
import { CustomTable, Column } from "./Table";
import ReportHeader from "./ReportHeader";
import { reformatDate } from "../../helpers/dateHelpers";
import { useHistory } from "react-router-dom";
import { IndividualDetailState } from "../../pages/teacher/IndividualDetailReport";
import { useEffect } from "react";
import { track } from "../../services/heapService";

interface Row {
  id: string;
  name: string;
  gender: string;
  surveyDate: string;
}

type IndividualListingProps = {
  responseResult: SurveyWithResponses;
};

const defaultColumns = [
  { columnTitle: "Name", columnField: "name", columnWidth: "65%" },
  { columnTitle: "Gender", columnField: "gender", columnWidth: "15%" },
  {
    columnTitle: "Completed on",
    columnField: "surveyDate",
    columnWidth: "20%",
  },
] as Column[];

const mapResponses = (responses: SurveyResponse[]): Row[] => {
  if (responses) {
    return responses.map(
      (response) =>
        ({
          id: response.email,
          name: `${response.firstName} ${response.lastName}`,
          gender: response.gender,
          surveyDate: reformatDate(response.createdAt!, "dd MMM yyyy"),
        } as Row)
    );
  } else {
    return [];
  }
};

export const IndividualListing: FC<IndividualListingProps> = ({
  responseResult,
}: IndividualListingProps) => {
  const history = useHistory();
  const data: Row[] = useMemo(() => mapResponses(responseResult.responses), [
    responseResult.responses,
  ]);

  const report: SurveyReport = {
    overview: {
      surveyName: responseResult.surveyName,
      createdAt: responseResult.createdAt,
      responseCount: responseResult.responses?.length,
      studentCount: responseResult.studentCount,
    },
  } as SurveyReport;

  useEffect(() => {
    track("View - Report", {
      responseCount: report.overview.responseCount,
      studentCount: report.overview.studentCount,
    });
  }, []); // eslint-disable-line

  const getReportResponse = (id: string): SurveyResponse | undefined =>
    responseResult.responses.find(
      (r) => r.email === id
    ); /*TODO: should it be email or responseId*/

  const handleRowClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: Row,
    index: number
  ): void => {
    history.push({
      pathname: `/report/individual/${responseResult.surveyId}/${index}`,
      state: {
        surveyResponse: getReportResponse(row.id),
        surveyName: responseResult.surveyName,
      } as IndividualDetailState,
    });
  };

  return (
    <>
      <ReportHeader
        report={report}
        showConfidenceColour={false}
        hidePrintButton
      />
      <CustomTable
        columns={defaultColumns}
        data={data}
        defaultOrderBy="name"
        handleRowClick={handleRowClick}
        searchText="Search by name"
        searchBy={["name"]}
      ></CustomTable>
    </>
  );
};
