import React, { FC } from "react";
import { Typography, Divider, makeStyles } from "@material-ui/core";
import { QuestionLabel } from "./IndividualDetail";

type Prop = {
  questionLabel: QuestionLabel;
  answer: string[];
};

const useStyles = makeStyles((theme) => ({
  answerList: {
    paddingInlineStart: "20px",
  },
}));

export const IndividualAnswer: FC<Prop> = ({ questionLabel, answer }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h4" component="h3" gutterBottom>
        {questionLabel.label}
      </Typography>
      {questionLabel.description && (
        <Typography variant="subtitle2" gutterBottom>
          {questionLabel.description}
        </Typography>
      )}
      <Divider orientation="horizontal" />
      <ul className={classes.answerList}>
        {answer.map((a) => (
          <li key={a}>{a}</li>
        ))}
      </ul>
    </div>
  );
};
