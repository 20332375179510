export const positionOptions = {
  "HPE/PDHPE Teacher": "HPE/PDHPE Teacher",
  "Wellbeing Coordinator": "Wellbeing Coordinator",
  "Head of Wellbeing": "Head of Wellbeing",
  Principal: "Principal",
  "Deputy Principal": "Deputy Principal",
  "Assistant Principal": "Assistant Principal",
  "Head Teacher": "Head Teacher",
  "Year Advisor": "Year Advisor",
  Counsellor: "Counsellor",
  "School Nurse": "School Nurse",
  Teacher: "Teacher",
  Other: "Other",
};

export interface SignupFormValues {
  firstName: string;
  email: string;
  password: string;
  isSchoolUser: string | boolean;
  schoolName?: string;
  organisationName?: string;
  postcode?: string;
  position: string;
  positionOther?: string;
  recaptchaInput: string;
  recaptchaSiteKey: string;
}
