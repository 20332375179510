import React from "react";
import { createPortal } from "react-dom";

type Props = {
  root?: string | HTMLElement | null;
};
export default class Portal extends React.Component<Props> {
  root: HTMLElement;
  el: HTMLElement;

  constructor(props: Props) {
    super(props);
    let rootForId: HTMLElement | null = null;
    if (props.root) {
      rootForId =
        typeof props.root === "string"
          ? document.getElementById(props.root)
          : props.root;
    }
    this.root = rootForId || document.body; // default to body
    this.el = document.createElement("div");
  }

  componentDidMount() {
    this.root.appendChild(this.el);
  }

  componentWillUnmount() {
    this.root.removeChild(this.el);
  }

  render() {
    return createPortal(this.props.children, this.el);
  }
}
