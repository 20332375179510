import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

declare global {
  interface Window {
    QSI: any; //Qualtrics
  }
}

// to detect route change and manually trigger a page view event for qualtrics
// NOTE: the qualtrics init script is set in the head of index.html
export const useQualtrics = (): void => {
  const location = useLocation();
  const [qsi, setQSI] = useState(window.QSI);

  useEffect(() => {
    window.addEventListener("qsi_js_loaded", () => setQSI(window.QSI), false);
  }, []); //eslint-ignore-line - deliberately use empty deps to force this effect to happen one-time-only

  useEffect(() => {
    if (!qsi) return;

    qsi.API.unload();
    qsi.API.load();
    qsi.API.run();
  }, [location.pathname, qsi]);
};
