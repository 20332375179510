import { makeStyles } from "@material-ui/core";
import { Props as RatingProps } from "./index";

export const useSubRatingStyles = makeStyles((theme) => ({
  fieldsetContainer: {
    width: "100%",
  },
  buttonAndLabelContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      flexDirection: "column",
    },
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "15px",
    },
  },
  ratingButton: {
    "&&&": {
      width: "56px",
      height: "56px",
      color: theme.palette.primary.main,
      fontSize: "20px",
      fontWeight: 600,
      marginBottom: "8px",
    },
  },
  ratingButtonSelected: {
    "&&&": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    "&&&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  labelContainer: {
    marginBottom: "24px",
    color: theme.palette.text.primary,
    fontSize: "16px",
  },
}));

export const useRatingStyles = makeStyles((theme) => ({
  textContainer: (props: Partial<RatingProps>) => ({
    color: props.error ? theme.palette.error.main : theme.palette.text.primary,
  }),
  errorContainer: {
    fontSize: 16,
    paddingTop: 16,
  },
  subRatingContainer: {
    padding: "24px 0",
    borderBottom: "1px solid #8898AA",
    "&:last-child": {
      borderBottom: "none",
    },
  },
}));
