import { SurveyData } from "@reachout/student-snapshot-api-types";
import { ObjectSchema } from "yup";
import { AnyResponse } from "../../components/SurveyQuestion/types";

export type Props = {
  surveyData: SurveyData;
};

export type State = {
  ready: boolean;
  initialValues: Record<string, AnyResponse>;
  formSchema: ObjectSchema | null;
};

export enum ActionType {
  SET_INITIAL_VALUES = "setInitialValues",
  SET_FORM_SCHEMA = "setFormSchema",
  SET_READY = "setReady",
}

export type Action =
  | {
      type: ActionType.SET_INITIAL_VALUES;
      payload: Record<string, AnyResponse>;
    }
  | {
      type: ActionType.SET_FORM_SCHEMA;
      payload: ObjectSchema;
    }
  | {
      type: ActionType.SET_READY;
      payload: boolean;
    };
