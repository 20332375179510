import React, { FC, ReactElement, ReactNode } from "react";
import { CheckboxProps } from "@material-ui/core/Checkbox";
import { FormControlLabelProps } from "@material-ui/core";
import { FastField } from "formik";
import { snakeCase } from "../../helpers";
import RawCheckbox from "./RawCheckbox";

export type Props = CheckboxProps & {
  id?: string;
  name: string;
  label: ReactNode;
  labelPlacement?: FormControlLabelProps["labelPlacement"];
  fullWidth?: boolean;
};

// Checkbox that connects to formik directly, used in many places
const Checkbox: FC<Props> = ({
  id,
  name,
  fullWidth = true,
  labelPlacement = "end",
  ...others
}) => {
  const _id = id || snakeCase(name);
  return (
    <FastField name={name}>
      {({ field, meta }: any): ReactElement => {
        const showError = Boolean(meta.touched && meta.error);

        return (
          <RawCheckbox
            id={_id}
            showError={showError}
            fullWidth={fullWidth}
            labelPlacement={labelPlacement}
            error={meta.error}
            {...others}
            {...field}
          />
        );
      }}
    </FastField>
  );
};

export default Checkbox;
