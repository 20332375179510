import React, { FC } from "react";
import {
  ReportQuestion,
  ChartData,
} from "@reachout/student-snapshot-api-types";
import { Typography, makeStyles } from "@material-ui/core";
import ChartStackbar from "./ChartStackbar";
import { ratingColourScheme, reverseRatingColourScheme } from "./chartHelpers";
import { standardBorder } from "../../../styles/borders";

export type ReportChartBarRatingsProps = {
  chart: ReportQuestion;
};

export type BarRatingProps = {
  data: ChartData;
};

const useStyles = makeStyles((theme) => ({
  splitContainer: {
    padding: "16px 0",
    borderBottom: standardBorder,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    "& > *": {
      flex: "1 1 50%",
      [theme.breakpoints.down("md")]: {
        flex: "1 1 100%",
      },
    },
    "&:last-child": {
      border: "none",
    },
    "@media print": {
      padding: "8px 0",
      flexDirection: "row !important",
    },
  },
  splitPanelContainer: {
    display: "flex",
    flexDirection: "column",
  },
  stackContainer: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "16px",
    },
  },
}));

const ReportChartBarRatings: FC<ReportChartBarRatingsProps> = ({ chart }) => {
  const styles = useStyles();
  return (
    <div className="all-bar-ratings-chart-container">
      <Typography variant="h4" component="h3" gutterBottom>
        {chart.chartTitle}
      </Typography>

      {chart.chartData.map((data, i) => (
        <div
          className={`bar-rating-chart-container ${styles.splitContainer}`}
          key={data.title}
        >
          <Typography>{data.title}</Typography>

          <div className={styles.stackContainer}>
            <ChartStackbar
              showAllInTooltip
              chartData={data.nested!}
              key={i.toString()}
              colourScheme={
                data.higherIsBetter
                  ? reverseRatingColourScheme
                  : ratingColourScheme
              }
              barSize={20}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReportChartBarRatings;
