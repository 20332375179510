import React, { FC, ReactElement } from "react";
import { Card, Typography, LinearProgress } from "@material-ui/core";
import { useSurveyStyles } from "./styles";
import { SurveySummary } from "@reachout/student-snapshot-api-types";
import { SurveyLinkDialog } from "./SurveyLinkDialog";
import { Link } from "react-router-dom";
import { SendButton } from "../StyledButton";
import { getUrlWithPath } from "../../helpers";

type SurveyCardProps = {
  survey: SurveySummary;
};

const buildSurveyLink = (survey: SurveySummary): string =>
  getUrlWithPath(`/survey/${survey.surveyId}`);

export const SurveyCard: FC<SurveyCardProps> = ({
  survey,
}: SurveyCardProps): ReactElement => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const surveyLink = buildSurveyLink(survey);
  const classes = useSurveyStyles();

  return (
    <Card className={classes.surveyCard} variant="outlined">
      <SurveyLinkDialog
        open={open}
        handleClose={handleClose}
        surveyLink={surveyLink}
        title={survey.surveyName}
      />
      <div className={classes.cardTitle}>
        <Typography
          noWrap
          variant="h4"
          component="p"
          title={survey.surveyName} // in case of truncation, show full name on hover
        >
          {survey.surveyName}
        </Typography>
        <Typography
          className={classes.surveyYearTitle}
          color="textSecondary"
          align="right"
        >
          {survey.yearLevel}
        </Typography>
      </div>
      <div className={classes.cardContent}>
        <Typography color="textSecondary">{survey.shortTitle}</Typography>
        <div className={classes.responseGroup}>
          <Typography>
            Responses: {survey.responseCount} of {survey.studentCount}
          </Typography>
          <LinearProgress
            className={classes.progressBar}
            variant="determinate"
            value={(survey.responseCount / survey.studentCount) * 100}
          />
        </div>
      </div>
      <div className={classes.cardActions}>
        {survey.deIdentified !== "true" && survey.responseCount > 0 ? (
          <Link
            to={
              survey.isIdentified
                ? `/report/individual/${survey.surveyId}`
                : `/report/group/${survey.surveyId}`
            }
            className={classes.surveyCardButton}
          >
            <Typography variant="h4" component="span" color="primary">
              See {survey.isIdentified ? "snapshots" : "snapshot"}
            </Typography>
          </Link>
        ) : (
          <div></div>
        )}
        <SendButton id="survey-card-open" onClick={handleClickOpen} />
      </div>
    </Card>
  );
};
