import { makeStyles } from "@material-ui/core";

import { standardBorderWithRadius } from "../../styles/borders";
import { deadCenter } from "../../styles/layouts";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      padding: "0 16px 16px 16px",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "16px",
    },
  },
  formContainer: {
    ...deadCenter,
  },
  form: {
    width: 650,
  },
  allQuestionsContainer: {
    marginTop: 40,
    paddingBottom: 24,
  },
  questionContainer: {
    ...standardBorderWithRadius,
    padding: 40,
    "&:not(:last-child)": {
      marginBottom: 16,
    },
  },
  hasRedBorder: {
    borderColor: theme.palette.error.main,
  },
  formActionsContainer: {
    ...deadCenter,
    justifyContent: "flex-end",
  },
  textContainer: {
    whiteSpace: "pre-line",
    paddingBottom: "8px",
  },
  spinnerContainer: {
    ...deadCenter,
    minHeight: "50vh",
  },
}));

export default useStyles;
