import React, { FC } from "react";
import { ReportQuestion } from "@reachout/student-snapshot-api-types";
import ReportChartStackbar from "./charts/ReportChartStackbar";
import { makeStyles } from "@material-ui/core";
import { standardBorderWithRadius } from "../../styles/borders";
import { ReportChartBar } from "./charts/ReportChartBar";
import ReportChartBarRatings from "./charts/ReportChartBarRatings";

type Props = {
  chart: ReportQuestion;
  highlightsOnly?: boolean;
};
const useStyles = makeStyles((theme) => ({
  chartWrapper: {
    ...standardBorderWithRadius,
    padding: 40,

    "& svg": {
      height: "30px !important",
    },

    [theme.breakpoints.down("md")]: {
      padding: 16,
    },
    "@media print": {
      padding: 0,
      paddingTop: 16,
      border: "none",
    },
  },
}));

const ReportChart: FC<Props> = ({ chart, highlightsOnly }) => {
  const styles = useStyles();

  let chartComponent;

  switch (chart.chartType) {
    case "stackbar-ratings": {
      chartComponent = <ReportChartStackbar chart={chart} ratings />;
      break;
    }
    case "stackbar": {
      chartComponent = <ReportChartStackbar chart={chart} />;
      break;
    }
    case "bar-ratings": {
      chartComponent = <ReportChartBarRatings chart={chart} />;
      break;
    }
    case "bar":
    default: {
      chartComponent = (
        <ReportChartBar chart={chart} highlightsOnly={highlightsOnly} />
      );
      break;
    }
  }

  return <div className={styles.chartWrapper}>{chartComponent}</div>;
};

export default ReportChart;
