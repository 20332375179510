import React, { FC, useState } from "react";
import TeacherLayout from "../../layout/TeacherLayout";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import welcomeBackground from "../../assets/image/welcome.svg";
import { useHistory } from "react-router-dom";
import SimpleDialog from "../../components/Dialog/SimpleDialog";
import { Heading } from "../../components/Headings";
import { StyledButton } from "../../components/StyledButton";

const useStyles = makeStyles((theme) => ({
  welcomeContainer: {
    backgroundImage: `url(${welcomeBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom",
    backgroundSize: "contain",
    [theme.breakpoints.up("sm")]: {
      height: "730px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 78px)", // 78 = header height (68) + 10px
      backgroundSize: "125%",
    },
  },
  descriptionContainer: {
    [theme.breakpoints.up("sm")]: {
      margin: "50px 100px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: 16,
    },
  },
  welcomeTitle: {
    marginBottom: "10px",
  },
  welcomeDescription: {
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "350px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "250px",
    },
  },
  infoDialog: {
    marginBottom: "10px",
  },
}));

const Welcome: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const handleInfoDialogOpen = (open: boolean): void => {
    setInfoDialogOpen(open);
  };

  const infoDialog = (
    <SimpleDialog
      dialogId="student-snapshot-info"
      dialogTitle="Student Snapshot"
      open={infoDialogOpen}
      handleClose={(): void => {
        handleInfoDialogOpen(false);
      }}
      maxWidth="sm"
    >
      <Typography paragraph>
        Helping teachers to support students transitioning to secondary school.
      </Typography>
      <Typography paragraph>
        Student Snapshot is a free, easy-to-use student survey that helps
        teachers form relationships with their students while designing
        meaningful transition programs that meet the needs of their cohort.
      </Typography>
      <Typography paragraph>
        ReachOut developed Student Snapshot in collaboration with teachers and
        students, and it trialled successfully in Australian schools in December
        2019.
      </Typography>
    </SimpleDialog>
  );

  return (
    <TeacherLayout>
      {infoDialog}
      <div className={classes.welcomeContainer}>
        <div className={classes.descriptionContainer}>
          <Heading
            className={classes.welcomeTitle}
            text="Welcome to Student Snapshot"
            align="left"
            variant="h1"
          />
          <Typography variant="body2" className={classes.welcomeDescription}>
            Start by creating a survey to better understand your students and
            ways to improve their wellbeing.
          </Typography>
          <Grid container spacing={1}>
            <Grid item>
              <StyledButton
                onClick={(): void => {
                  history.push("/surveys/create");
                }}
                color="secondary"
                variant="contained"
                text="Create survey"
                id="welcome-create-survey"
              />
            </Grid>
            <Grid item>
              <StyledButton
                id="welcome-more-info"
                text="More info"
                onClick={(): void => {
                  handleInfoDialogOpen(true);
                }}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </TeacherLayout>
  );
};

export default Welcome;
