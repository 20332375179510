import React, { FC, useCallback, useMemo, useState } from "react";
import { ReportQuestion } from "@reachout/student-snapshot-api-types";
import { Typography, makeStyles, Divider, Grid } from "@material-ui/core";
import { VictoryBar } from "victory";
import { defaultColourScheme, highlightsItemNumber } from "./chartHelpers";

type Props = {
  chart: ReportQuestion;
  barSize?: number;
  highlightsOnly?: boolean;
};

const useStyles = makeStyles({
  chart: {
    alignItems: "center",
    marginTop: "16px",
  },
  bar: {
    display: "flex",
    alignItems: "center",
    maxHeight: 40,
  },
  label: {
    minWidth: "30px",
    marginRight: "16px",
  },
});

export const ReportChartBar: FC<Props> = ({
  chart,
  barSize = 20,
  highlightsOnly,
}: Props) => {
  const classes = useStyles();
  const chartData = useMemo(
    () =>
      highlightsOnly
        ? chart.chartData.slice(0, highlightsItemNumber)
        : chart.chartData,
    [chart.chartData, highlightsOnly]
  );

  const [width, setWidth] = useState(1);
  const measure = useCallback(
    (node: HTMLElement | null) => {
      if (!node) return;
      const chartWidth = node.getBoundingClientRect().width;

      setWidth(chartWidth);
    },
    [setWidth]
  );
  return (
    <div>
      <Typography variant="h4" component="h3" gutterBottom>
        {chart.chartTitle}
      </Typography>
      <Grid container spacing={2} className={classes.chart}>
        {chartData.map((cd, i) => {
          return (
            <React.Fragment key={i}>
              <Grid item xs={6}>
                <Typography variant="body1">{cd.label}</Typography>
              </Grid>
              <Grid item xs={6} className={classes.bar} ref={measure}>
                <Typography variant="body1" className={classes.label}>
                  {cd.value}%
                </Typography>
                <VictoryBar
                  alignment="start"
                  barWidth={barSize}
                  data={[{ y: [cd.value] }]}
                  domain={{ x: [0, 1], y: [0, 100] }}
                  horizontal
                  cornerRadius={{ bottom: barSize / 2 }}
                  height={barSize}
                  width={width}
                  padding={{ top: 0, bottom: 0 }}
                  style={{ data: { fill: defaultColourScheme[i] } }}
                />
              </Grid>

              {i < chartData.length - 1 && (
                <Grid item xs={12}>
                  <Divider orientation="horizontal" />
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    </div>
  );
};
