import React, { FC } from "react";
import { Switch, Route } from "react-router-dom";

import { SurveyResponseContext } from "./state/student/surveyResponse";
import { SurveyQuestionsContext } from "./state/student/surveyQuestions";
import { ReceiveMailContext } from "./state/student/receiveMail";

import StudentDetails from "./pages/student/StudentDetails";
import Survey from "./pages/student/Survey";
import SurveyOneLanding from "./pages/student/Landing";
import Success from "./pages/student/Success";

const StudentApp: FC = () => {
  return (
    <ReceiveMailContext>
      <SurveyQuestionsContext>
        <SurveyResponseContext>
          <Switch>
            <Route
              exact
              path="/survey/:surveyId/welcome"
              component={SurveyOneLanding}
            />
            <Route
              path="/survey/:surveyId/details"
              component={StudentDetails}
            />
            {/* todo: use when email capture ready */}
            {/* <Route path="/survey/:surveyId/email" component={Email} /> */}
            <Route path="/survey/success" component={Success} />
            <Route path="/survey/:surveyId" component={Survey} />
          </Switch>
        </SurveyResponseContext>
      </SurveyQuestionsContext>
    </ReceiveMailContext>
  );
};

export default StudentApp;
