import React, { FC } from "react";
import { Header } from "../components/Header";
import Footer from "../components/Footer";

const TeacherLayout: FC = ({ children }) => (
  <div>
    <Header />
    {children}
    <Footer />
  </div>
);

export default TeacherLayout;
