import React, { FC } from "react";

const NotFound: FC = () => {
  return (
    <div>
      <div>
        <h1>Not Found...</h1>
      </div>
    </div>
  );
};

export default NotFound;
