import React, { FC, ReactElement } from "react";
import { MenuItem, SelectProps, TextField } from "@material-ui/core";
import { snakeCase } from "../../helpers";
import { Field } from "formik";

export type SingleSelectProps = {
  name: string;
  label: string;
  menuItems: Record<string, string>;
};

const SingleSelect: FC<SingleSelectProps & SelectProps> = ({
  id,
  label,
  name,
  menuItems,
  fullWidth = true,
  ...props
}: SingleSelectProps & SelectProps) => {
  const _id = id || snakeCase(name);
  return (
    <Field name={name}>
      {({ field, meta }: any): ReactElement => {
        const showError = Boolean(meta.touched && meta.error);
        return (
          <TextField
            select
            label={label}
            id={_id}
            error={showError}
            helperText={showError ? meta.error : ""}
            variant="outlined"
            fullWidth={fullWidth}
            {...props}
            {...field}
          >
            {Object.entries(menuItems).map(([value, label]) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    </Field>
  );
};

export default SingleSelect;
