import * as Yup from "yup";

export const loginSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const complexityMessage =
  "Password must be 8 or more characters including a capital letter, a lowercase letter, and a number";

export const signupSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, complexityMessage)
    .matches(/[A-Z]/, complexityMessage)
    .matches(/[a-z]/, complexityMessage)
    .matches(/\d/, complexityMessage)
    .required("Password is required"),
  isSchoolUser: Yup.string().required().oneOf(["true", "false"]),
  schoolName: Yup.string().when("isSchoolUser", {
    is: "true",
    then: Yup.string().required("School name is required"),
    otherwise: Yup.string(),
  }),
  organisationName: Yup.string().when("isSchoolUser", {
    is: "false",
    then: Yup.string().required("Organisation name is required"),
    otherwise: Yup.string(),
  }),
  postcode: Yup.string()
    .matches(/^\d{4}$/, "Postcode must be 4 digits")
    .when("isSchoolUser", {
      is: "false",
      then: Yup.string().required("Postcode is required"),
      otherwise: Yup.string(),
    }),
  position: Yup.string().required("Please select your position"),
  positionOther: Yup.string().when("position", {
    is: "Other",
    then: Yup.string().required("Position name is required"),
    otherwise: Yup.string(),
  }),
});
