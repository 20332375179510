import constate from "constate";
import { SurveyResponse } from "@reachout/student-snapshot-api-types";
import { Dispatch, SetStateAction, useState } from "react";

const useSurvey = (): {
  surveyResponseState: Partial<SurveyResponse>;
  setSurveyResponseState: Dispatch<SetStateAction<Partial<SurveyResponse>>>;
} => {
  const [surveyResponseState, setSurveyResponseState] = useState<
    Partial<SurveyResponse>
  >({});

  return { surveyResponseState, setSurveyResponseState };
};

export const [SurveyResponseContext, useSurveyResponseContext] = constate(
  useSurvey
);

export const isStudentValid = (
  surveyResponse: Partial<SurveyResponse>
): boolean => {
  return Boolean(
    surveyResponse.email &&
      surveyResponse.firstName &&
      surveyResponse.lastName &&
      surveyResponse.gender
  );
};
