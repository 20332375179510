import React, { FC } from "react";
import { makeStyles, Typography, Grid, Button } from "@material-ui/core";
import {
  RecommendedResource,
  ReportSection as ReportSectionModel,
} from "@reachout/student-snapshot-api-types";
import cn from "classnames";

import ReportChart from "./ReportChart";
import ReportInsight from "./ReportInsight";
import RecommendedResourcesCTA from "../../components/Resources/RecommendedResourcesCTA";
import { collapseOnPrint } from "../../styles/layouts";

type Props = {
  section: ReportSectionModel;
  resources: RecommendedResource[];
  highlightsOnly?: boolean;
  showDetails: () => void;
};

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginBottom: 16,
    display: "flex",
    alignItems: "baseline",

    "&:not(:first-of-type)": {
      pageBreakBefore: "always",
    },

    "@media screen": {
      marginTop: 24,
    },
  },
  chartContainer: {
    pageBreakInside: "avoid",

    "&:not(:last-child)": {
      marginBottom: 16,
    },

    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
    },

    ...collapseOnPrint,
  },
  detailsLink: {
    fontWeight: 600,
    marginLeft: 8,
  },
}));

const ReportSection: FC<Props> = ({
  section,
  resources,
  showDetails,
  highlightsOnly = false,
}) => {
  const styles = useStyles();
  const firstInsightList = resources?.map((r) => r.title) || [];
  return (
    <>
      <Typography variant="h3" component="h2" className={styles.sectionTitle}>
        {section.sectionTitle}
        {highlightsOnly && (
          <Button
            color="primary"
            onClick={showDetails}
            className={cn(styles.detailsLink, "hide-for-print")}
          >
            See more
          </Button>
        )}
      </Typography>
      {section.charts.map((chart, i) => (
        <Grid
          container
          spacing={2}
          key={chart.chartTitle}
          className={styles.chartContainer}
        >
          <Grid item xs={12} sm={8} md={9}>
            <ReportChart chart={chart} highlightsOnly={highlightsOnly} />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            {section.isResourceDeterminingQuestion && i === 0 ? ( // Only show resources for first chart
              <RecommendedResourcesCTA
                id={`recommended-resources-${
                  highlightsOnly ? "summary" : "details"
                }`}
                topWorries={firstInsightList}
              />
            ) : (
              <ReportInsight insight={chart.insight} />
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default ReportSection;
