import { parseISO, format } from "date-fns";
import { enAU } from "date-fns/locale";

export const snakeCase = (value: string | undefined): string => {
  return value === undefined
    ? ""
    : value
        .replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toLowerCase())
        .join("_");
};

export const toAuDateString = (datetime: string): string => {
  const formattedDate = format(parseISO(datetime), "dd/MM/yyyy", {
    locale: enAU,
  });
  return formattedDate;
};

export const getUrlWithPath = (path: string): string => {
  const hostname = `${window.location.protocol}//${window.location.hostname}${
    window.location.hostname === "localhost" ? `:${window.location.port}` : ""
  }`;

  return `${hostname}${path.startsWith("/") ? path : `/${path}`}`;
};
