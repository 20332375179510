import React, { FC, Fragment } from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import cn from "classnames";
import { ChartData } from "@reachout/student-snapshot-api-types";
import ChartDot from "./ChartDot";

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
  },
  label: {
    flex: "1 1 auto",
  },
  value: {
    display: "block",
    textAlign: "right",
  },
});

export type LegendData = ChartData & { index?: number; colour?: string };

type Props = {
  className?: string;
  style?: Record<string, string | number | undefined>;
  data: LegendData[];
  colourScheme: string[];
  includeHeaders?: boolean;
};

const ChartLegend: FC<Props> = ({
  className,
  style,
  data,
  colourScheme,
  includeHeaders = false,
}) => {
  const styles = useStyles();
  return (
    <Grid container spacing={2} className={className} style={style}>
      {includeHeaders && (
        <>
          <Grid item xs={8}>
            <Typography variant="body1" className={styles.title}>
              Key
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              className={cn(styles.title, styles.value)}
            >
              Value
            </Typography>
          </Grid>
        </>
      )}
      {data.map((entry, idx) => {
        const index = typeof entry.index === "number" ? entry.index : idx;
        return (
          <Fragment key={entry.label}>
            <Grid item xs={8}>
              <Typography variant="body1" className={styles.label}>
                <ChartDot colour={entry.colour || colourScheme[index]} />
                {entry.label}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" className={styles.value}>
                {entry.value}%
              </Typography>
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
};

export default ChartLegend;
