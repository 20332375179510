import React, { FC } from "react";
import { TypographyProps, Typography } from "@material-ui/core";

type Props = {
  text: string;
};

export const Heading: FC<
  Props & TypographyProps & { component?: React.ElementType }
> = ({ text, ...titleProps }) => {
  return (
    <Typography variant="h2" component="h1" {...titleProps}>
      {text}
    </Typography>
  );
};

export const CenteredHeading: FC<
  Props & TypographyProps & { component?: React.ElementType }
> = ({ text, ...titleProps }) => {
  return <Heading {...titleProps} text={text} align="center" variant="h1" />;
};
