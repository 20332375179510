import React, { FC } from "react";
import {
  ButtonProps,
  Button,
  Tooltip,
  TooltipProps,
  Typography,
} from "@material-ui/core";
import { Link as SendIcon, Print as PrintIcon } from "@material-ui/icons";

export type Props = {
  text: string;
};

const defaultButtonProps: ButtonProps = {
  variant: "outlined",
  color: "primary",
};

export const StyledButton: FC<
  Props & ButtonProps & { component?: React.ElementType }
> = ({ text, ...buttonProps }) => {
  return <Button {...buttonProps}>{text}</Button>;
};

StyledButton.defaultProps = defaultButtonProps;

export const SendButton: FC<ButtonProps> = ({ ...buttonProps }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<SendIcon style={{ transform: "rotate(-45deg)" }} />}
      {...buttonProps}
    >
      Share
    </Button>
  );
};

type DownloadTooltip = {
  tooltip?: string;
  placement?: TooltipProps["placement"];
};
export const DownloadButton: FC<ButtonProps & DownloadTooltip> = ({
  tooltip = "Download report",
  placement = "bottom",
  ...buttonProps
}) => {
  const button = (
    <Button id="download" variant="outlined" color="primary" {...buttonProps}>
      <PrintIcon />
    </Button>
  );

  if (tooltip) {
    return (
      <Tooltip placement={placement} title={<Typography>{tooltip}</Typography>}>
        {button}
      </Tooltip>
    );
  }

  return button;
};
