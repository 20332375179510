import constate from "constate";
import { Dispatch, SetStateAction, useState } from "react";
import { SurveyQuestions } from "@reachout/student-snapshot-api-types";

const useSurveyQuestions = (): {
  surveyQuestions: SurveyQuestions | null;
  setSurveyQuestions: Dispatch<SetStateAction<SurveyQuestions | null>>;
} => {
  const [
    surveyQuestions,
    setSurveyQuestions,
  ] = useState<SurveyQuestions | null>(null);
  return { surveyQuestions, setSurveyQuestions };
};

export const [SurveyQuestionsContext, useSurveyQuestionsContext] = constate(
  useSurveyQuestions
);
