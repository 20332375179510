import { ThemeOptions } from "@material-ui/core/styles";

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: "#0d61a9",
    },
    secondary: {
      main: "#ffef5e",
    },
    text: {
      primary: "#000",
      secondary: "#525F7F",
    },
    error: {
      main: "#B30C1A",
    },
  },
  typography: {
    fontFamily: "'Open Sans', Roboto, Helvetica, sans-serif",
    h1: {
      fontSize: 28,
      fontWeight: 600,
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
      color: "#8898AA",
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
    },
    MuiCheckbox: {
      color: "primary",
    },
    MuiRadio: {
      color: "primary",
    },
  },
  // global theme change to not capitalise button text
  overrides: {
    MuiButton: {
      label: {
        textTransform: "none",
      },
    },
    MuiCssBaseline: {
      "@global": {
        ".hide-for-print": {
          "@media print": {
            display: "none !important",
          },
        },
        // visually hidden / SR only - used for accessible labels for icon buttons
        ".sr-only": {
          position: "absolute",
          left: -10000,
          top: "auto",
          width: "1px",
          height: "1px",
          overflow: "hidden",
        },
      },
    },
  },
};

export default theme;
