import axios, { AxiosError, AxiosInstance } from "axios";

import { UserInfo } from "../Auth";
import { SignupFormValues } from "../pages/teacher/accounts/constants";

import { getUrlWithPath } from "../helpers";

const authClient = (): AxiosInstance =>
  axios.create({
    baseURL: process.env.REACT_APP_AUTH_BASE_URL,
    method: "post",
    withCredentials: true,
  });

const withDomain = (
  payload: Record<string, string | boolean> = {}
): Record<string, string> => ({
  domain: "schools",
  ...payload,
});

const nameForUser = (user: Record<string, string | null>): string => {
  if (user.name) return user.name;
  if (user.displayName) return user.displayName.replace(/\d+$/, "");

  const parts = user.emailAddress?.split("@");
  if (parts) {
    return parts[0].replace(
      /([^.+]+)/,
      (m) => m.charAt(0).toUpperCase() + m.slice(1).toLowerCase()
    );
  }

  return "";
};

const normaliseUser = (user: Record<string, string | null>): UserInfo => {
  return {
    email: user.emailAddress as string,
    userId: user.userId as string,
    name: nameForUser(user),
  };
};

export const logout = async (): Promise<void> => {
  await authClient().request({
    url: "/logout",
    data: withDomain(),
  });
};

export const getUserProfile = async (): Promise<UserInfo | null> => {
  try {
    const { data } = await authClient().request({
      url: "/profile",
      data: withDomain(),
    });
    const { token: _token, ...user } = data; // eslint-disable-line
    return normaliseUser(user);
  } catch (ex) {
    // pass
  }
  return null;
};

export const login = async (
  username: string,
  password: string,
  staySignedIn = false
): Promise<UserInfo> => {
  try {
    const { data } = await authClient().request({
      url: "/login",
      data: withDomain({ username, password, staySignedIn }),
    });

    return normaliseUser(data);
  } catch (ex) {
    if (ex.isAxiosError) {
      const res = (ex as AxiosError).response;
      const status = res?.status;

      if (status && status >= 400 && status < 500) {
        if (res?.data?.errorMessage?.includes("not_approved")) {
          throw new Error(
            "Your account hasn't been verified yet.\nPlease check your inbox for a verification email to continue."
          );
        }
        throw new Error("Please check your email and password and try again.");
      } else if (status !== 200) {
        throw new Error("An unexpected error occurred");
      }
    }
    throw ex;
  }
};

export const signup = async (
  signupDetails: SignupFormValues
): Promise<boolean> => {
  try {
    await authClient().request({
      url: "/signup",
      data: withDomain({
        ...signupDetails,
        referer: getUrlWithPath("/login"), // return to login after verifying
      } as Record<string, string>),
    });

    return true;
  } catch (ex) {
    if (ex.isAxiosError) {
      const res = (ex as AxiosError).response;
      const status = res?.status;
      if (status && status >= 400) {
        if (status === 401) throw new Error(res?.data?.errorMessage);
        throw new Error(
          "Please check your information is complete and correct.\nIf you already have a ReachOut Schools account you do not need to sign up again."
        );
      }
    }
    throw ex;
  }
};
