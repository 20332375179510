import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const getClient = async (requiresUserAuth = true): Promise<AxiosInstance> => {
  return axios.create({
    baseURL: process.env.REACT_APP_STUDENT_SNAPSHOT_API_BASE_URL,
    headers: {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
    },
    withCredentials: requiresUserAuth,
  });
};

export const request = async (
  config: AxiosRequestConfig,
  requiresUserAuth = true
): Promise<any> => {
  try {
    const client = await getClient(requiresUserAuth);
    const response = await client.request(config);
    if (response.status !== 200) {
      throw new Error(response.data);
    }
    return response.data;
  } catch (e) {
    console.error("Student snapshot request failed", e);
    return null;
  }
};
