import React from "react";
import { Selection } from "victory";
import { ChartData } from "@reachout/student-snapshot-api-types";

export type CornerRadius =
  | number
  | {
      bottomLeft?: number;
      topLeft?: number;
      bottomRight?: number;
      topRight?: number;
    };

export const nonHighlightedColour = "#ACBCCF";

export const defaultColourScheme = ["#276AC7", "#58A8ED", "#70DAFF"].concat(
  Array.from(Array(30), () => nonHighlightedColour) // pad out the scheme so everything else is grey
);

export const highlightsItemNumber = 3;

export const ratingColourScheme = [
  "#769526", // Green
  "#9FC019",
  "#FFDA25",
  "#FFB047",
  "#FF6545", // Red
];

export const reverseRatingColourScheme = [...ratingColourScheme].reverse();

export const getCornerRadius = (
  i: number,
  data: ChartData[],
  barSize: number,
  ignoreEmpty = false
): CornerRadius => {
  const radiusSize = barSize / 2;
  const radius: CornerRadius = {
    bottomLeft: 0,
    bottomRight: 0,
    topLeft: 0,
    topRight: 0,
  };
  const firstIndex = ignoreEmpty
    ? data.findIndex(({ value }) => Boolean(value))
    : 0;
  const lastIndex = ignoreEmpty
    ? data.length -
      [...data].reverse().findIndex(({ value }) => Boolean(value)) -
      1
    : data.length - 1;

  if (i === firstIndex) {
    radius.bottomLeft = radiusSize;
    radius.bottomRight = radiusSize;
  }
  if (i === lastIndex) {
    radius.topLeft = radiusSize;
    radius.topRight = radiusSize;
  }
  return radius;
};

// update the position of the label/tooltip based on the mouse location
// the event handler schema isn't exported, so can't correctly type the object
/* eslint-disable */
export const tooltipFollowingMouse: any = {
  target: "data",
  eventHandlers: {
    onMouseOver: (evt: React.MouseEvent) => {
      const { x, y } = Selection.getSVGEventCoordinates(evt);
      return [
        {
          target: "labels",
          mutation: () => ({
            x,
            y,
            active: true,
          }),
        },
        {
          target: "data",
          mutation: () => ({ active: true }),
        },
      ];
    },
    onMouseMove: (evt: React.MouseEvent) => {
      const { x, y } = Selection.getSVGEventCoordinates(evt);
      return {
        target: "labels",
        mutation: () => ({
          x,
          y,
          active: true,
        }),
      };
    },
    onMouseOut: () => {
      return [
        { target: "labels", mutation: () => ({ active: false }) },
        {
          target: "data",
          mutation: () => ({ active: false }),
        },
      ];
    },
  },
};
/* eslint-enable */

export const chartTextStyle = {
  fontFamily: "'Open Sans', Roboto, Helvetica, sans-serif",
  fontSize: 12,
};
