import React, { useContext, FC, useState, ReactElement } from "react";
import { useHistory, Link } from "react-router-dom";
import { Formik } from "formik";
import { Typography, Link as MuiLink } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import cn from "classnames";

import { AuthContext } from "../../../Auth";
import { StyledButton } from "../../../components/StyledButton";
import TeacherLayout from "../../../layout/TeacherLayout";
import { TextField, PasswordField, Checkbox } from "../../../components/Form";

import useStyles from "./styles";
import { loginSchema } from "./validationSchemas";
import { postLoginRedirect } from "../../../services/redirectService";

const Login: FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const [message, setMessage] = useState<string | null>(null);
  const { login } = useContext(AuthContext);

  return (
    <TeacherLayout>
      <Formik
        initialValues={{
          email: "",
          password: "",
          staySignedIn: false,
        }}
        initialErrors={{ email: "Email is required" }}
        validationSchema={loginSchema}
        onSubmit={async (values, actions): Promise<void> => {
          try {
            const user = await login(
              values.email,
              values.password,
              values.staySignedIn
            );
            await postLoginRedirect(user, history);
          } catch (ex) {
            setMessage(ex.message);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, isSubmitting, isValid }): ReactElement => (
          <form className={styles.root} onSubmit={handleSubmit}>
            <Typography className={styles.title} variant="h1">
              Login
            </Typography>

            <Alert severity="info" className={styles.explainer}>
              <Typography>
                Student Snapshot is powered by ReachOut Schools.
                <br />
                You can log in here using the same account details.
              </Typography>
            </Alert>

            <div className={styles.formFields}>
              <TextField
                className={styles.formField}
                label="Email"
                name="email"
                type="email"
              />
              <PasswordField
                className={styles.formField}
                label="Password"
                name="password"
              />
              <div className={cn(styles.formField, styles.twoColumns)}>
                <Checkbox
                  fullWidth={false}
                  name="staySignedIn"
                  label="Stay signed in?"
                />
                <MuiLink
                  href={process.env.REACT_APP_FORGOT_PASSWORD_LINK}
                  target="_blank"
                >
                  Forgot password?
                </MuiLink>
              </div>
            </div>

            {message && (
              <Alert severity="error" className={styles.responseMessage}>
                <Typography>{message}</Typography>
              </Alert>
            )}

            <div className={styles.formActions}>
              <div className={styles.alternateFlowAction}>
                <span>Don&apos;t have an account?</span>
                <MuiLink id="signup-from-login" component={Link} to="/signup">
                  Sign up now
                </MuiLink>
              </div>
              <StyledButton
                id="login"
                text="Login"
                variant="contained"
                type="submit"
                disabled={!isValid || isSubmitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </TeacherLayout>
  );
};

export default Login;
