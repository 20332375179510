import { makeStyles } from "@material-ui/core";
import { standardBorderWithRadius } from "../../../styles/borders";
import { formFields, formField } from "../../../styles/forms";
import { deadCenter } from "../../../styles/layouts";

export default makeStyles((theme) => ({
  root: {
    ...standardBorderWithRadius,
    boxSizing: "border-box",
    width: 500,
    margin: "0 auto",
    padding: 24,
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      width: "auto",
      margin: 16,
      padding: 16,
    },
  },
  title: {
    marginBottom: 24,
  },
  explainer: {
    marginBottom: 24,
  },
  formFields: {
    ...formFields,
  },
  formActions: {
    marginTop: 16,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      "& > :last-child": {
        flex: "1 1 100%",
        marginTop: 16,
      },
    },
  },
  alternateFlowAction: {
    display: "flex",
    alignItems: "center",
    "& :not(:last-child)": {
      marginRight: 8,
    },
  },
  formField: {
    ...formField,
  },
  responseMessage: {
    whiteSpace: "pre-line",
    margin: "16px 0",
  },
  successMessage: {
    ...deadCenter,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    zIndex: 5,
    background: "#fdf388",
    borderRadius: 4,

    "& p": {
      padding: 16,
      fontWeight: "normal",
      lineHeight: 1.6,
    },
  },
  twoColumns: {
    display: "flex",
    flexWrap: "wrap-reverse",
    alignItems: "center",
    justifyContent: "space-between",
  },
  agreeToTncs: {
    marginTop: 8,
  },
  recaptchaBranding: {
    marginTop: 8,
    fontSize: 12,
  },
}));
