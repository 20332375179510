import React, { useState, FC, useEffect } from "react";
import * as authService from "./services/authService";
import { SignupFormValues } from "./pages/teacher/accounts/constants";
import { identifyUser, resetIdentity } from "./services/heapService";

export const AuthContext = React.createContext<Auth>({
  userInfo: null,
  isAuthenticated: false,
  login: () => Promise.resolve({} as UserInfo),
  logout: () => Promise.resolve(),
  signup: () => Promise.resolve(true),
});

export type Auth = {
  userInfo: UserInfo | null;
  login: (
    email: string,
    password: string,
    staySignedIn?: boolean
  ) => Promise<UserInfo>;
  signup: (signupDetails: SignupFormValues) => Promise<boolean>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
};

export interface UserInfo {
  userId: string;
  name: string;
  email: string;
}

type AuthProps = {
  initialUser?: UserInfo | null;
};

const Auth: FC<AuthProps> = ({ children, initialUser = null }) => {
  const [isChecking, setCheckingUser] = useState(true);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(initialUser);

  const isAuthenticated = Boolean(userInfo);

  const validateUserToken = async (): Promise<void> => {
    try {
      if (!userInfo) {
        const user = await authService.getUserProfile();
        if (user) {
          setUserInfo(user);
          identifyUser(user.userId);
          (window as any).roCurrentUserId = user.userId; // for Qualtrics
        }
      }
    } finally {
      setCheckingUser(false);
    }
  };

  // perform existing login check when loading the page
  useEffect(() => {
    // don't check token on student pages
    if (!window.location.pathname.includes("survey/")) {
      validateUserToken();
    } else {
      setCheckingUser(false);
    }
  }, []); // eslint-disable-line

  const login = async (
    email: string,
    password: string,
    staySignedIn?: boolean
  ): Promise<UserInfo> => {
    const user = await authService.login(email, password, staySignedIn);
    setUserInfo(user);
    identifyUser(user.userId);
    return user;
  };

  const signup = async (signupDetails: SignupFormValues): Promise<boolean> =>
    authService.signup(signupDetails);

  const logout = async (): Promise<void> => {
    await authService.logout();
    setUserInfo(null);
    resetIdentity();
    (window as any).roCurrentUserId = null;
  };

  return (
    <AuthContext.Provider
      value={{ login, logout, signup, userInfo, isAuthenticated }}
    >
      {isChecking ? <div></div> : children}
    </AuthContext.Provider>
  );
};

export default Auth;
