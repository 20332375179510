import React, { FC, useEffect, useState } from "react";
import { SurveyWithResponses } from "@reachout/student-snapshot-api-types";
import { getSurveyDetails } from "../../services/surveyService";
import TeacherLayout from "../../layout/TeacherLayout";
import { IndividualListing } from "../../components/Report/IndividualListing";
import { useReportStyles } from "../../components/Report/styles";
import { useParams } from "react-router-dom";
import { SurveyPathParams } from "../../types";

const IndividualReport: FC = () => {
  const classes = useReportStyles();
  const { surveyId } = useParams<SurveyPathParams>();
  const [
    responseResult,
    setResponseResult,
  ] = useState<SurveyWithResponses | null>(null);

  useEffect(() => {
    getSurveyDetails(surveyId).then(setResponseResult);
  }, [setResponseResult, surveyId]);

  return (
    <TeacherLayout>
      <div className={classes.report}>
        {responseResult && (
          <IndividualListing responseResult={responseResult} />
        )}
      </div>
    </TeacherLayout>
  );
};

export default IndividualReport;
