import constate from "constate";
import { Dispatch, SetStateAction, useState } from "react";

const useReceiveMail = (): {
  receiveMail: boolean | null;
  setReceiveMail: Dispatch<SetStateAction<boolean | null>>;
} => {
  const [receiveMail, setReceiveMail] = useState<boolean | null>(null);
  return { receiveMail, setReceiveMail };
};

export const [ReceiveMailContext, useReceiveMailContext] = constate(
  useReceiveMail
);
