import React, {
  ChangeEvent,
  CSSProperties,
  FC,
  forwardRef,
  HTMLAttributes,
  ReactElement,
} from "react";

import {
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import cn from "classnames";

type Props = {
  error?: string;
  touched: boolean;
  onChange: (e: ChangeEvent<{}>, val: string | null) => void;
  value: string;
  options: string[];
  className?: string;
  label: string;
};

type RowProps = {
  data: ReactElement[];
  index: number;
  style: CSSProperties;
};

type ListProps = {
  children: ReactElement[];
  className: string;
  id: string;
  role: string;
  onMouseDown: (e: MouseEvent) => void;
  "aria-labelledby": string;
} & HTMLAttributes<HTMLElement>;

const Row: FC<RowProps> = ({ data, index, style }) =>
  React.cloneElement(data[index], { style });

const OuterElementContext = React.createContext({});
const useStyles = makeStyles({
  list: {
    "-ms-overflow-style": "none",
  },
});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const classes = useStyles();
  const outerProps = React.useContext(OuterElementContext);
  return (
    <div
      ref={ref}
      {...props}
      {...outerProps}
      className={cn(classes.list, (outerProps as any).className)}
    />
  );
});
OuterElementType.displayName = "OuterElementType";

const AutocompleteList: FC<ListProps> = forwardRef<HTMLDivElement, ListProps>(
  (props, ref) => {
    const { children, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
    const itemSize = smUp ? 36 : 48;
    const height = 16 + Math.min(itemCount * itemSize, 200);

    return (
      <div ref={ref} style={{ width: "100%", height }}>
        <OuterElementContext.Provider value={other}>
          <AutoSizer>
            {({ width, height }): ReactElement => (
              <List
                width={width}
                height={height}
                itemCount={itemCount}
                itemSize={itemSize}
                itemData={children}
                outerElementType={OuterElementType}
                innerElementType="ul"
                overscanCount={5}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        </OuterElementContext.Provider>
      </div>
    );
  }
);
AutocompleteList.displayName = "AutocompleteList";

const Autocomplete: FC<Props> = ({
  error,
  touched,
  onChange,
  value,
  options,
  className,
  label,
}) => {
  const showError = Boolean(error && touched);

  return (
    <MuiAutocomplete
      freeSolo
      autoSelect
      openOnFocus={false}
      onChange={onChange}
      value={value}
      options={options}
      className={className}
      renderInput={(params: AutocompleteRenderInputParams): ReactElement => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          error={showError}
          helperText={showError ? error : ""}
        />
      )}
      ListboxComponent={AutocompleteList as any} // eslint-disable-line
    />
  );
};

export default Autocomplete;
