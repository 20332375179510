import {
  SurveyResponseAnswer,
  RatingAnswer,
} from "@reachout/student-snapshot-api-types";

declare global {
  interface Window {
    heap: {
      track: (
        eventName: string,
        params?: Record<string, string | number>
      ) => void;
      resetIdentity: () => void;
      identify: (userId: string) => void;
    };
  }
}

const isHeapLoaded = (): boolean => typeof window.heap === "object";

export const identifyUser = (userId: string): void => {
  if (isHeapLoaded()) {
    window.heap.identify(userId);
  }
};
export const resetIdentity = (): void => {
  if (isHeapLoaded()) {
    window.heap.resetIdentity();
  }
};

export const track = (
  eventName: string,
  data?: Record<string, string | number>
): void => {
  if (isHeapLoaded()) {
    data ? window.heap.track(eventName, data) : window.heap.track(eventName);
  }
};

export const trackAnswers = (
  templateId: string,
  responseAnswers: SurveyResponseAnswer[]
): void => {
  if (isHeapLoaded()) {
    responseAnswers.forEach((responseAnswer) => {
      if (
        responseAnswer.answer.length > 0 &&
        typeof responseAnswer.answer[0] === "string"
      ) {
        (responseAnswer.answer as string[]).forEach((answer) => {
          window.heap.track("Submitted Response", {
            templateId: templateId,
            questionId: responseAnswer.questionId,
            answerValue: answer,
          });
        });
      } else {
        (responseAnswer.answer as RatingAnswer[]).forEach(({ id, value }) => {
          window.heap.track("Submitted Response", {
            templateId: templateId,
            questionId: id,
            answerValue: value,
          });
        });
      }
    });
  }
};
