import React, { FC } from "react";
import {
  RecommendedResource,
  ReportSection as ReportSectionModel,
} from "@reachout/student-snapshot-api-types";
import ReportSection from "./ReportSection";

type Props = {
  highlights: ReportSectionModel[];
  sections: ReportSectionModel[];
  resources: RecommendedResource[];
  highlightsOnly?: boolean;
  showDetails: () => void;
};
const ReportContent: FC<Props> = ({
  highlights,
  sections,
  resources,
  showDetails,
  highlightsOnly = false,
}) => {
  const sectionsToShow = highlightsOnly ? highlights : sections;
  return (
    <div>
      {sectionsToShow.map((section: ReportSectionModel) => {
        return (
          <ReportSection
            key={section.sectionTitle}
            section={section}
            resources={resources}
            showDetails={showDetails}
            highlightsOnly={highlightsOnly}
          />
        );
      })}
    </div>
  );
};

export default ReportContent;
