import React, { FC } from "react";
import { SurveyCard } from "./SurveyCard";
import { SurveySummary } from "@reachout/student-snapshot-api-types";
import { Grid } from "@material-ui/core";

type SurveyListingProps = {
  surveys: SurveySummary[];
};

export const SurveyListing: FC<SurveyListingProps> = ({
  surveys,
}: SurveyListingProps) => {
  return (
    <div>
      <Grid container spacing={2}>
        {surveys.map((survey) => (
          <Grid key={survey.surveyId} item xs={12} sm={6} md={4} lg={3}>
            <SurveyCard survey={survey} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
