import React, { FC, useState, ChangeEvent } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  TableHead,
  TableSortLabel,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/Sort";
import { useTableStyles } from "./styles";
import dataOrderBy from "lodash.orderby";

export interface Column {
  columnField: string;
  columnTitle: string;
  columnWidth: string;
}

type TableProps = {
  columns: Column[];
  data: any[];
  defaultOrderBy: string;
  handleRowClick: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: any,
    index: number
  ) => void;
  searchText: string;
  searchBy: string[];
};

type Order = "asc" | "desc";

export const CustomTable: FC<TableProps> = ({
  columns,
  data,
  handleRowClick,
  defaultOrderBy,
  searchText,
  searchBy = ["name"],
}: TableProps) => {
  const classes = useTableStyles();

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>(defaultOrderBy);
  const [search, setSearch] = useState<string>("");

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ): void => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value);
  };

  const filterBy = (row: Record<string, string>): boolean => {
    if (search === "") return true;
    const result = Object.entries(row).filter(
      ([key, value]) =>
        searchBy.includes(key) &&
        value.toLowerCase().includes(search.toLowerCase())
    );
    return result.length > 0;
  };

  return (
    <div>
      <Paper elevation={0} className={classes.table}>
        <TableContainer>
          <Toolbar className={classes.toolbar}>
            <OutlinedInput
              placeholder={searchText}
              inputProps={{ "aria-label": searchText }}
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Toolbar>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(({ columnField, columnTitle, columnWidth }) => (
                  <TableCell
                    key={columnTitle}
                    sortDirection={order}
                    width={columnWidth}
                  >
                    <TableSortLabel
                      active={orderBy === columnField}
                      direction={orderBy === columnField ? order : "asc"}
                      onClick={(event): void => handleSort(event, columnField)}
                      IconComponent={SortIcon}
                    >
                      <Typography className={classes.columnTitle}>
                        {columnTitle}
                      </Typography>
                      {orderBy === columnField ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataOrderBy(data, [orderBy], [order])
                .filter(filterBy)
                .map((row, index): any => (
                  <TableRow
                    key={row.id}
                    hover
                    className={classes.tableRow}
                    onClick={(event): void => {
                      handleRowClick(event, row, index);
                    }}
                  >
                    {columns.map(({ columnField }) => (
                      <TableCell
                        key={columnField}
                        className={classes.tableRowText}
                      >
                        {row[columnField]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {data.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} className={classes.noResult}>
                    <Typography variant="overline">
                      There are no results
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};
