import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import { Done as DoneIcon } from "@material-ui/icons";
import { SurveyTemplate } from "@reachout/student-snapshot-api-types";
import cn from "classnames";

import { useSurveyStyles } from "./styles";
import { CenteredHeading } from "../Headings";
import { ExternalLink } from "../Link";

type Props = {
  template: SurveyTemplate;
};

export const SurveyTemplateItem: FC<Props> = ({ template }) => {
  const classes = useSurveyStyles();
  return (
    <div className={classes.surveyTemplateItemContainer}>
      <img src={template.imageSrc} alt="" />
      <Typography variant="body1" className={classes.surveyTemplateTitle}>
        {template.title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {template.description}
      </Typography>

      <div className={classes.selectedIndicator}>
        <DoneIcon fontSize="large" />
      </div>
    </div>
  );
};

export const SurveyTemplateItemWithoutImage: FC<Props> = ({
  template,
}: Props) => {
  const classes = useSurveyStyles();
  return (
    <div
      className={cn(
        classes.surveyTemplateItemContainer,
        classes.surveyTemplateItem
      )}
    >
      <CenteredHeading text={template.title} />
      <Typography variant="body2">{template.description}</Typography>
      <ExternalLink
        className={classes.exampleLink}
        href={template.exampleUrl}
        label="See questions and sample report"
      />
    </div>
  );
};
