import React, { FC } from "react";
import { Link } from "@material-ui/core";
import cn from "classnames";

import useStyles from "./styles";
import logo from "../assets/image/reachout-logo.svg";
import { privacyPolicyProps, contactUsProps } from "../components/Link";
import HamburgerMenu from "../components/HamburgerMenu";

const links = [
  {
    ...privacyPolicyProps,
    label: "Privacy",
  },
  {
    ...contactUsProps,
    label: "Contact Us",
  },
];

const SurveyLayout: FC = ({ children }) => {
  const s = useStyles();

  return (
    <div className={s.layoutContainer}>
      <div className={s.headerContainer}>
        <div className={s.logoContainer}>
          <img src={logo} alt="Reachout Logo" className={s.logo} />
        </div>

        <div className={s.hamburgerMenuContainer}>
          <HamburgerMenu menuItems={links} />
        </div>
      </div>

      <div className="children-container">{children}</div>

      <div className={cn(s.footerContainer, "hide-for-print")}>
        {links.map((link) => (
          <Link
            id={`footer-${link.description}`}
            key={link.description}
            href={link.href}
            target="_blank"
            className={s.link}
          >
            {link.label}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SurveyLayout;
