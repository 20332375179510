import {
  SurveyData,
  SurveySummary,
  SurveyResponse,
  SurveyTemplate,
  SurveyReport,
  SurveyQuestions,
  SurveyWithResponses,
} from "@reachout/student-snapshot-api-types";
import { request } from "./apiClient";

// Teacher endpoints
export const getSurveyTemplates = async (): Promise<SurveyTemplate[]> =>
  request({
    url: "/templates",
    method: "get",
  });

export const createSurvey = async (
  survey: SurveyData
): Promise<SurveySummary> =>
  request({ url: "/surveys", method: "post", data: survey });

export const getSurveySummaries = async (): Promise<SurveySummary[]> =>
  request({ url: "/surveys", method: "get" });

export const getSurveyDetails = async (
  surveyId: string
): Promise<SurveyWithResponses> =>
  request({
    url: `/surveys/${surveyId}`,
    method: "get",
  });

export const getReportDetails = async (
  surveyId: string
): Promise<SurveyReport> =>
  request({
    url: `/surveys/${surveyId}/report`,
    method: "get",
  });

// Student facing endpoint
export const getSurveyById = (surveyId: string): Promise<SurveyQuestions> =>
  request(
    {
      url: `/survey/${surveyId}`,
      method: "get",
    },
    false
  );

export const submitSurvey = async (
  surveyResponse: SurveyResponse
): Promise<boolean> => {
  try {
    await request(
      {
        url: `/survey/${surveyResponse.surveyId}`,
        method: "put",
        data: surveyResponse,
      },
      false
    );
    return true;
  } catch (e) {
    // todo: handle error
    return false;
  }
};
