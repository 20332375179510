import React, { FC, Fragment } from "react";
import { ReportOverview as ReportOverviewModel } from "@reachout/student-snapshot-api-types";
import { Typography, makeStyles } from "@material-ui/core";

type Props = {
  overview: ReportOverviewModel;
};

const useStyles = makeStyles((theme) => ({
  overviewBox: {
    margin: "16px 0",
    padding: 24,
    backgroundColor: "rgba(172, 188, 207, 0.1)",
    borderRadius: 4,
  },
}));

const ReportOverview: FC<Props> = ({ overview }) => {
  const styles = useStyles();
  return (
    <div>
      <Typography variant="h3" component="h2">
        Introduction to cohort snapshot
      </Typography>
      <div className={styles.overviewBox}>
        <Typography paragraph>{overview.surveyPurpose}</Typography>
        <Typography paragraph>{overview.confidenceLevel}</Typography>
        <Typography>
          {overview.keyTopics.map((t, i) => (
            <Fragment key={t}>
              {i > 0 ? ", " : null}
              <strong>‘{t}’</strong>
            </Fragment>
          ))}{" "}
          {overview.keyTopicsDescription}
        </Typography>
      </div>
    </div>
  );
};

export default ReportOverview;
