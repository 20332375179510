import React, { FC } from "react";
import {
  SurveyReport,
  ReportOverview,
} from "@reachout/student-snapshot-api-types";
import { Button, Typography, IconButton } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import cn from "classnames";

import ChartDot from "./charts/ChartDot";
import { reformatDate } from "../../helpers/dateHelpers";
import { ratingColourScheme } from "./charts/chartHelpers";
import { useHeaderStyles } from "./styles";
import { DownloadButton } from "../StyledButton";

type Props = {
  report: SurveyReport;
  onShowDetailedView?: () => void;
  onHideDetailedView?: () => void;
  isDetailedView?: boolean;
  showConfidenceColour?: boolean;
  hidePrintButton?: boolean;
};

const getConfidenceColour = ({
  responseCount,
  studentCount,
}: ReportOverview): string => {
  const ratio = responseCount / studentCount;
  if (ratio >= 0.7) return ratingColourScheme[0];
  if (ratio >= 0.4) return ratingColourScheme[1];

  return ratingColourScheme[ratingColourScheme.length - 1];
};

const ReportHeader: FC<Props> = ({
  report,
  onShowDetailedView,
  onHideDetailedView,
  isDetailedView = false,
  showConfidenceColour = true,
  hidePrintButton = false,
}) => {
  const styles = useHeaderStyles();
  const confidenceColour = getConfidenceColour(report.overview);
  const isHighlightsView = !isDetailedView && onShowDetailedView;
  return (
    <div className={styles.header}>
      <Typography variant="h2" component="h1" className={styles.title}>
        {isDetailedView && (
          <IconButton
            onClick={onHideDetailedView}
            className={cn(styles.backButton, "hide-for-print")}
            aria-label="Back to summary"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        {report.overview.surveyName}
        {isHighlightsView
          ? " - Highlights"
          : isDetailedView
          ? " - All answers"
          : ""}
      </Typography>
      <div className={cn(styles.headerActions, "hide-for-print")}>
        <Typography className={styles.headerItem}>
          Created: {reformatDate(report.overview.createdAt, "MMM dd yyyy")}
        </Typography>
        <Typography className={styles.headerItem}>
          {showConfidenceColour && <ChartDot colour={confidenceColour} />}{" "}
          Responses: {report.overview.responseCount} /{" "}
          {report.overview.studentCount}
        </Typography>
        {isHighlightsView && (
          <Button
            id="report-show-detail"
            color="primary"
            variant="contained"
            onClick={onShowDetailedView}
          >
            See all answers
          </Button>
        )}
        {!hidePrintButton && (
          <DownloadButton onClick={(): void => window.print()} />
        )}
      </div>
    </div>
  );
};

export default ReportHeader;
