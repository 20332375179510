import React, { FC, ReactElement } from "react";
import { Typography, Hidden, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { SurveyTemplate } from "@reachout/student-snapshot-api-types";
import { Form, Formik, FormikProps, FormikHelpers } from "formik";
import cn from "classnames";

import SingleSelect from "../Form/SingleSelect";
import { useSurveyStyles } from "./styles";
import { createSurveyFormValidationSchema } from "../validationSchema";
import { TextField } from "../Form";
import { StyledButton } from "../StyledButton";
import {
  SurveyTemplateItem,
  SurveyTemplateItemWithoutImage,
} from "./SurveyTemplateItem";
import DotsStepper from "./DotsStepper";
import { ExternalLink } from "../Link";

export interface CreateSurveyForm {
  surveyName: string;
  yearLevel: string;
  studentCount: string;
}

const maxTitleLength = 256;
const YearLevel: Record<string, string> = {
  "Year 7": "Year 7",
  "Year 8": "Year 8",
  "Year 9": "Year 9",
  "Year 10": "Year 10",
  "Year 11": "Year 11",
  "Year 12": "Year 12",
};

interface AlertProps {
  showAlert: boolean;
  alertVariant: string;
  alertMessage: string;
}

export type HandleSubmitProps = {
  templateId: string;
  values: CreateSurveyForm;
  actions: FormikHelpers<CreateSurveyForm>;
};

type SurveyFormProps = {
  handleCancel: () => void;
  handleSubmit: (props: HandleSubmitProps) => Promise<void>;
  buttonText: string;
  initialValues: CreateSurveyForm;
  template: SurveyTemplate;
  steps: number;
  currentStep: number;
};

export const SurveyForm: FC<SurveyFormProps> = ({
  handleCancel,
  handleSubmit,
  buttonText,
  initialValues,
  template,
  steps,
  currentStep,
}: SurveyFormProps) => {
  const classes = useSurveyStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createSurveyFormValidationSchema}
      onSubmit={(values, actions): Promise<void> =>
        handleSubmit({ templateId: template.templateId, values, actions })
      }
      key={template.templateId}
    >
      {({
        status,
        isValid,
        isSubmitting,
      }: FormikProps<CreateSurveyForm>): ReactElement => {
        return (
          <Form>
            <Grid
              container
              spacing={2}
              className={classes.surveyTemplatesContainer}
            >
              <Grid item xs={12} sm={6}>
                <Hidden xsDown>
                  <div
                    className={cn(classes.surveyTemplateItem, classes.outlined)}
                  >
                    <SurveyTemplateItem template={template} />
                    <ExternalLink
                      className={classes.exampleLink}
                      href={template.exampleUrl}
                      label="See questions and sample report"
                    />
                  </div>
                </Hidden>
                <Hidden smUp>
                  <SurveyTemplateItemWithoutImage template={template} />
                </Hidden>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.formFields}>
                <Typography variant="body2" className={classes.formTitle}>
                  Enter survey details
                </Typography>
                <TextField
                  label="Survey Name"
                  name="surveyName"
                  placeholder="Eg. Year 7: Term 1"
                  inputProps={{
                    maxLength: maxTitleLength,
                  }}
                  className={classes.formField}
                />
                <SingleSelect
                  label={"Year Level"}
                  name={"yearLevel"}
                  menuItems={YearLevel}
                  className={classes.formField}
                />
                <TextField
                  label="Student Count"
                  name="studentCount"
                  placeholder="Eg. 150"
                  type="number"
                  className={classes.formField}
                  inputProps={{
                    min: 1,
                  }}
                />
                <div className={classes.formAlert}>
                  {status?.showAlert && (
                    <Alert severity={status.alertVariant}>
                      {status.alertMessage}
                    </Alert>
                  )}
                </div>
              </Grid>
            </Grid>
            <Hidden xsDown>
              <div className={classes.surveyStep2ContainerActions}>
                <div className={classes.stepper}>
                  <DotsStepper steps={steps} activeStep={currentStep} />
                </div>
                <div className={classes.actions}>
                  <StyledButton
                    id="survey-form-cancel"
                    text="Back"
                    onClick={handleCancel}
                  />
                  <StyledButton
                    id="survey-form-submit"
                    variant="contained"
                    text={buttonText}
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  />
                </div>
              </div>
            </Hidden>
            <Hidden smUp>
              <div className={classes.surveyStep2ContainerActions}>
                <StyledButton
                  id="survey-form-cancel-mobile"
                  text="Back"
                  onClick={handleCancel}
                />
                <DotsStepper steps={steps} activeStep={currentStep} />
                <StyledButton
                  id="survey-form-submit-mobile"
                  variant="contained"
                  text={buttonText}
                  type="submit"
                  disabled={!isValid || isSubmitting}
                />
              </div>
            </Hidden>
          </Form>
        );
      }}
    </Formik>
  );
};
