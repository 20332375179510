import React, { FC, useReducer, Reducer } from "react";
import { useRatingStyles } from "./styles";
import {
  RatingQuestion,
  RatingAnswer,
} from "@reachout/student-snapshot-api-types";
import { SetFieldTouchedFunction, SetFieldValueFunction } from "../types";
import SubRating from "./SubRating";
import { Typography } from "@material-ui/core";

export type Props = {
  question: RatingQuestion;
  value: RatingAnswer[] | null;
  error?: string | undefined;
  promptContainerClass?: string;
  textContainerClass?: string;
  setFieldTouched: SetFieldTouchedFunction;
  setFieldValue: SetFieldValueFunction;
};

type State = {
  questionId: string;
  value: RatingAnswer[];
  functions: {
    setFieldValue: SetFieldValueFunction;
    setFieldTouched: SetFieldTouchedFunction;
  };
};

export type Action = { type: "SET_SUBRATING"; payload: RatingAnswer };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_SUBRATING": {
      const updatedValue = [
        ...state.value.filter((v) => v.id !== action.payload.id),
        action.payload,
      ];
      state.functions.setFieldValue(state.questionId, updatedValue);
      return {
        ...state,
        value: updatedValue,
      };
    }
    default: {
      return state;
    }
  }
};

const Rating: FC<Props> = ({
  question,
  value,
  promptContainerClass = "",
  error,
  setFieldTouched,
  setFieldValue,
}) => {
  const s = useRatingStyles({ error });
  if (!value) {
    value = [];
  }

  const [, dispatch] = useReducer<Reducer<State, Action>>(reducer, {
    value,
    questionId: question.id,
    functions: {
      setFieldValue,
      setFieldTouched,
    },
  });

  return (
    <>
      <div className="sub-ratings-container">
        {question.ratings.map((r) => (
          <div key={r.id} className={s.subRatingContainer}>
            <SubRating
              {...r}
              value={value}
              dispatch={dispatch}
              promptContainerClass={promptContainerClass}
            />
          </div>
        ))}
      </div>

      {error && (
        <Typography color="error" className={s.errorContainer}>
          *{error}
        </Typography>
      )}
    </>
  );
};

export default Rating;
