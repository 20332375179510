import React, { FC } from "react";
import { Link, makeStyles, Typography } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useParams } from "react-router-dom";
import cn from "classnames";

import {
  contentDividingBorder,
  standardBorderWithRadius,
} from "../../styles/borders";
import teacherWithStudents from "../../assets/image/teacher-with-students.svg";
import { SurveyPathParams } from "../../types";

export type Props = {
  id: string;
  topWorries: string[];
};

const useStyles = makeStyles((theme) => ({
  container: {
    ...standardBorderWithRadius,
    display: "flex",
    flexDirection: "column",
    color: theme.palette.text.primary,
    padding: "24px",
    backgroundColor: "inherit",
    textAlign: "left",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  headingContainer: {
    borderBottom: contentDividingBorder(theme),
    padding: "8px 0 8px 0",
    marginBottom: "8px",
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    marginBottom: "8px",
  },
  subHeading: {
    color: theme.palette.text.secondary,
  },
  image: {
    height: "100%",
    width: "100%",
  },
  openInNew: {
    color: "#0373CF",
    height: "16px",
    width: "16px",
  },
}));

const RecommendedResourcesCTA: FC<Props> = ({ id, topWorries }) => {
  const s = useStyles();
  const { surveyId } = useParams<SurveyPathParams>();

  if (topWorries.length < 1) return null;

  const subheadingString =
    topWorries.length === 1
      ? "Based on top worry:"
      : `Based on top ${topWorries.length} worries:`;

  return (
    <Link
      id={id}
      className={cn(s.container, "hide-for-print")}
      href={`/report/group/${surveyId}/resources`}
      target="_blank"
    >
      <img
        className={s.image}
        src={teacherWithStudents}
        alt="teacher with students"
      />

      <div className={s.headingContainer}>
        <div className="text-container">
          <Typography className={s.heading} variant="h4">
            Recommended resources
          </Typography>
        </div>
        <div className="button-container">
          <OpenInNewIcon className={s.openInNew} />
        </div>
      </div>
      <Typography className={s.subHeading}>{subheadingString}</Typography>

      {topWorries.map((w) => (
        <Typography key={w}>{w}</Typography>
      ))}
    </Link>
  );
};

export default RecommendedResourcesCTA;
