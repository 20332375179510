import React, { FC, useCallback, useState } from "react";
import { IconButton, makeStyles, TextFieldProps } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import TextField from "./TextField";

const useStyles = makeStyles({
  passwordField: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "stretch",
    width: (props: TextFieldProps): string =>
      props.fullWidth ? "100%" : "auto",
  },
  icon: {
    position: "absolute",
    right: 6,
    top: 7,
    padding: 8,
  },
});

const PasswordField: FC<TextFieldProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword, setShowPassword]
  );
  const classes = useStyles(props);
  const VisibilityIcon = showPassword ? Visibility : VisibilityOff;

  return (
    <div className={classes.passwordField}>
      <TextField {...props} type={showPassword ? "text" : "password"} />
      <IconButton className={classes.icon} onClick={togglePasswordVisibility}>
        <VisibilityIcon />
        <span className="sr-only">
          {showPassword ? "Hide password" : "Show password"}
        </span>
      </IconButton>
    </div>
  );
};

export default PasswordField;
