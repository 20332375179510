import React, { FC, useReducer, Reducer, useMemo } from "react";
import {
  MultipleChoiceQuestion,
  PossibleAnswer,
} from "@reachout/student-snapshot-api-types";
import Checkbox from "../Form/RawCheckbox";
import { makeStyles, Typography } from "@material-ui/core";
import { SetFieldTouchedFunction, SetFieldValueFunction } from "./types";

export type Props = {
  question: MultipleChoiceQuestion;
  value: string[];
  error?: string | undefined;
  setFieldTouched: SetFieldTouchedFunction;
  setFieldValue: SetFieldValueFunction;
};

const useStyles = makeStyles((theme) => ({
  textContainer: (props: Partial<Props>) => ({ //eslint-disable-line
    color: props.error ? theme.palette.error.main : theme.palette.text.primary,
  }),
  errorContainer: {
    fontSize: 16,
    marginTop: 16,
  },
  promptText: {
    fontSize: 16,
    marginBottom: 16,
  },
}));

type State = {
  questionId: string;
  max?: number;
  selected: PossibleAnswer[];
  value: string[];
  functions: {
    setFieldValue: SetFieldValueFunction;
    setFieldTouched: SetFieldTouchedFunction;
  };
};

type Action =
  | {
      type: "SELECT";
      payload: PossibleAnswer;
    }
  | {
      type: "UNSELECT";
      payload: PossibleAnswer;
    };

const reducer = (state: State, action: Action): State => {
  // trigger validation on this question
  state.functions.setFieldTouched(state.questionId, true);
  const isMX = Boolean(action.payload.isMutuallyExclusive);

  let updatedState = state;

  switch (action.type) {
    case "SELECT": {
      if (state.max && state.selected.length >= state.max) {
        // if maxed out and not radio button, or choosing mutex: leave as is
        if (!(isMX || state.max === 1)) {
          return state;
        }

        // for single select, or mutex: set the list to that single val
        updatedState = {
          ...state,
          selected: [action.payload],
        };
      } else {
        // for mutex: set the list to that single val,
        // otherwise remove all mutexes and set the add the new val to the list
        updatedState = {
          ...state,
          selected: isMX
            ? [action.payload]
            : [
                ...state.selected.filter((so) => !so.isMutuallyExclusive),
                action.payload,
              ],
        };
      }

      break;
    }

    case "UNSELECT": {
      // for any unselect, just remove the entry
      updatedState = {
        ...state,
        selected: state.selected.filter((x) => x !== action.payload),
      };
      break;
    }
  }

  // if state has changed, update formik
  if (updatedState !== state) {
    state.functions.setFieldValue(
      state.questionId,
      updatedState.selected.map((i) => i.label || i.value)
    );
  }

  return updatedState;
};

const MultipleChoice: FC<Props> = ({
  question,
  value,
  setFieldValue,
  setFieldTouched,
  error,
}) => {
  const s = useStyles({ error });
  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, {
    questionId: question.id,
    max: question.max,
    selected: [],
    value,
    functions: { setFieldValue, setFieldTouched },
  });

  const changeHandlers = useMemo(() => {
    return question.answerOptions.reduce(
      (agg, ao) => ({
        ...agg,
        [ao.label || ao.value]: (_: unknown, checked: boolean): void => {
          dispatch({
            type: checked ? "SELECT" : "UNSELECT",
            payload: ao,
          });
        },
      }),
      {} as Record<string, (e: unknown, checked: boolean) => void>
    );
  }, [dispatch, question]);

  const hasReachedMax = value.length === question.max;
  const isSingleSelection = question.max === 1;

  return (
    <>
      <Typography color="textSecondary" className={s.promptText}>
        {question.max
          ? isSingleSelection
            ? "Select one answer"
            : `Select up to ${question.max} that apply`
          : "Select all that apply"}
      </Typography>

      <div className="all-checkboxes-container">
        {question.answerOptions.map((ao) => {
          const selected = state.selected.includes(ao);
          const isExclusive = ao.isMutuallyExclusive;
          const answerKey = ao.label || ao.value;
          const disabled =
            hasReachedMax && !isSingleSelection && !selected && !isExclusive;

          return (
            <Checkbox
              key={answerKey}
              disabled={disabled}
              id={answerKey}
              label={answerKey}
              checked={selected}
              onChange={changeHandlers[answerKey]}
            />
          );
        })}
      </div>

      {error && (
        <Typography color="error" className={s.errorContainer}>
          *{error}
        </Typography>
      )}
    </>
  );
};

export default MultipleChoice;
