import React, { FC, Fragment } from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import {
  contentDividingBorder,
  standardBorderWithRadius,
} from "../../styles/borders";
import { SurveyAnswerAndLabel } from "./IndividualDetail";

const getInitials = (text: string): string => {
  return text
    .split(" ")
    .map((val) => val[0])
    .join("");
};

const useStyles = makeStyles((theme) => ({
  initials: {
    backgroundColor: "#FFE0A3",
    borderRadius: "50%",
    textAlign: "center",
    width: "45px",
    height: "45px",
    lineHeight: "45px",
    fontSize: "16px",
    margin: "10px 15px 10px 0",

    "@media print": {
      display: "none",
    },
  },
  nameSection: {
    display: "flex",
    alignItems: "center",

    "@media print": {
      marginBottom: 16,
    },
  },
  personalInsightWrapper: {
    alignItems: "baseline",
    ...standardBorderWithRadius,
    padding: 24,
    [theme.breakpoints.up("md")]: {
      padding: 40,
    },

    "@media print": {
      textAlign: "left",
      padding: 16,

      "& > div": {
        maxWidth: "calc(50% - 8px)",
        flexBasis: "50%",
      },
    },
  },
  personalInsight: {
    [theme.breakpoints.up("md")]: {
      padding: "0 16px",
      "&:not(:last-child)": {
        borderRight: contentDividingBorder(theme),
      },
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 12,
      marginBottom: 12,
      borderBottom: contentDividingBorder(theme),
    },
    [theme.breakpoints.down("xs")]: {
      "&:last-child": {
        borderBottom: "none",
      },
    },

    "@media print": {
      border: "none !important",
    },
  },
}));

type Prop = {
  fullName: string;
  answers: SurveyAnswerAndLabel[];
};

export const IndividualPersonalInsight: FC<Prop> = ({
  fullName,
  answers,
}: Prop) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.nameSection}>
        <div className={classes.initials}>{getInitials(fullName)}</div>
        <Typography variant="h3" component="h2">
          {fullName}
        </Typography>
      </div>
      <Grid
        container
        className={classes.personalInsightWrapper}
        direction="row"
        alignItems="center"
        justify="center"
      >
        {answers.map((response, index) => {
          return (
            <Fragment key={response.questionId}>
              <Grid
                item
                className={classes.personalInsight}
                xs={12}
                sm={6}
                md={3}
                lg
              >
                <Typography variant="h4" component="h3" gutterBottom>
                  {response.questionLabel.label}
                </Typography>
                <Typography>{response.answer.join(", ")}</Typography>
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </>
  );
};
