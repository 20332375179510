import React, { FC } from "react";
import { Toolbar, Typography, Button } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/image/reachout-logo.svg";

type DesktopMenuProps = {
  classes: any;
  name: string;
  isAuthenticated: boolean;
  logout: () => void;
};

export const DesktopMenu: FC<DesktopMenuProps> = ({
  classes,
  name,
  logout,
  isAuthenticated,
}) => {
  const location = useLocation();
  return (
    <Toolbar className={classes.sectionDesktop}>
      <img src={logo} className={classes.logo} alt="ReachOut logo" />
      <Button
        variant="text"
        component={Link}
        id="dashboard-link"
        to="/surveys"
        classes={{
          label: classes.title,
        }}
        disabled={!isAuthenticated || location.pathname === "/surveys"}
      >
        Student Snapshot
      </Button>

      {isAuthenticated && (
        <div className={classes.menuRight}>
          <Typography variant="body1" color="textPrimary">
            {name}
          </Typography>

          <Button
            variant="text"
            id="logout-link"
            color="primary"
            onClick={logout}
          >
            Log out
          </Button>
        </div>
      )}
    </Toolbar>
  );
};
