import React, { FC, ReactElement } from "react";
import { snakeCase } from "../../helpers";
import { FastField, Field, FieldProps } from "formik";
import {
  TextFieldProps,
  TextField as MaterialTextField,
} from "@material-ui/core";

type Props = {
  useFastField?: boolean;
};
const TextField: FC<TextFieldProps & Props> = ({
  useFastField = false,
  ...props
}) => {
  const id = props.id || snakeCase(props.name);
  const FormikField = useFastField ? FastField : Field;
  return (
    <FormikField name={props.name}>
      {({ field, meta }: FieldProps): ReactElement => {
        const showError = Boolean(meta.touched && meta.error);
        return (
          <MaterialTextField
            id={id}
            error={showError}
            helperText={showError ? meta.error : ""}
            fullWidth={
              typeof props.fullWidth === "boolean" ? props.fullWidth : true
            }
            variant="outlined"
            {...props}
            {...field}
          />
        );
      }}
    </FormikField>
  );
};

export default TextField;
