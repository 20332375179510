import React, { FC, useEffect } from "react";
import SurveyLayout from "../../layout/SurveyLayout";
import {
  makeStyles,
  CircularProgress,
  Typography,
  Button,
  Link,
} from "@material-ui/core";
import { deadCenter } from "../../styles/layouts";
import { useHistory, useParams } from "react-router-dom";
import { useSurveyQuestionsContext } from "../../state/student/surveyQuestions";
import { getSurveyById } from "../../services/surveyService";
import {
  termsAndConditionProps,
  privacyPolicyProps,
} from "../../components/Link";
import { Link as RRLink } from "react-router-dom";
import { useSurveyResponseContext } from "../../state/student/surveyResponse";
import { SurveyPathParams } from "../../types";

const useStyles = makeStyles((theme) => ({
  container: {
    ...deadCenter,
    width: "100%",
  },
  splitPanelContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 16px",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 1000,
      flex: "1 1 100%",
    },
  },
  splitPanel: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
  },
  leftPanel: {
    flex: "1 1 auto",
  },
  rightPanel: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      paddingTop: 150,
      flex: "1 1 auto",
    },
  },
  buttonContainer: {
    paddingTop: "16px",
  },
  spinnerContainer: {
    ...deadCenter,
    minHeight: "50vh",
  },
  mainInstruction: {
    fontSize: 20,
  },
  surveyImage: {
    minWidth: 250,
    maxWidth: 450,
    width: "25vw",
  },
}));

const Landing: FC = () => {
  const s = useStyles();
  const h = useHistory();
  const { surveyId } = useParams<SurveyPathParams>();
  const { surveyQuestions, setSurveyQuestions } = useSurveyQuestionsContext();
  const {
    surveyResponseState,
    setSurveyResponseState,
  } = useSurveyResponseContext();

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      const target = await getSurveyById(surveyId);

      if (target) {
        setSurveyQuestions(target);
        setSurveyResponseState({
          ...surveyResponseState,
          surveyId,
        });
      } else {
        h.push("/not-found");
      }
    };

    if (!surveyQuestions) {
      fetch();
    }
  }, [
    surveyQuestions,
    setSurveyQuestions,
    setSurveyResponseState,
    surveyId,
    surveyResponseState,
    h,
  ]);

  if (!surveyQuestions) {
    return (
      <SurveyLayout>
        <div className={s.spinnerContainer}>
          <CircularProgress />
        </div>
      </SurveyLayout>
    );
  }

  return (
    <SurveyLayout>
      <div className={s.container}>
        <div className={s.splitPanelContainer}>
          <div className={s.splitPanel}>
            <div className={s.leftPanel}>
              <Typography variant="h1" gutterBottom>
                {surveyQuestions.surveyName}
              </Typography>
              {surveyQuestions.isIdentified ? (
                <Typography color="textSecondary" className={s.mainInstruction}>
                  This is a short survey to help your teachers get to know you.
                  This will help them understand how they can best support you.
                </Typography>
              ) : (
                <>
                  <Typography
                    color="textSecondary"
                    className={s.mainInstruction}
                  >
                    This is a short survey to help your teachers understand how
                    your year group is going.
                  </Typography>
                  <br />
                  <Typography variant="h4" component="h2" gutterBottom>
                    Before you start the survey
                  </Typography>
                  <Typography color="textSecondary">
                    Your teachers will only see the responses as a whole year
                    group. By continuing you agree with ReachOut&apos;s{" "}
                    <Link
                      id={`survey-details-${termsAndConditionProps.description}`}
                      href={termsAndConditionProps.href}
                      target="_blank"
                    >
                      Terms & Conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      id={`survey-details-${privacyPolicyProps.description}`}
                      href={privacyPolicyProps.href}
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </Typography>
                </>
              )}

              <div className={s.buttonContainer}>
                <Button
                  id="start-survey"
                  variant="contained"
                  color="secondary"
                  component={RRLink}
                  to={`${
                    surveyQuestions.isIdentified
                      ? `/survey/${surveyId}/details`
                      : `/survey/${surveyId}`
                  }`}
                >
                  Start survey
                </Button>
              </div>
            </div>

            <div className={s.rightPanel}>
              <img
                alt="" // presentation only
                className={s.surveyImage}
                src={surveyQuestions.imageSrc}
              />
            </div>
          </div>
        </div>
      </div>
    </SurveyLayout>
  );
};

export default Landing;
