import { makeStyles } from "@material-ui/core";
import { contentDividingBorder } from "../styles/borders";

const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    [theme.breakpoints.up("md")]: {
      paddingTop: 100,
    },
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    backgroundColor: "transparent",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      top: 0,
      left: 0,
      position: "absolute",
      justifyContent: "flex-start",
    },
  },
  logo: {
    width: 80,
    [theme.breakpoints.up("md")]: {
      width: 100,
    },
  },
  logoContainer: {
    padding: 16,
    [theme.breakpoints.up("md")]: {
      padding: "16px 24px",
    },
  },
  footerContainer: {
    background: theme.palette.background.default,
    padding: "16px 24px",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      position: "fixed",
      left: 0,
      bottom: 0,
    },
  },

  link: {
    "&:not(:last-child)": {
      borderRight: contentDividingBorder(theme),
      paddingRight: 8,
      marginRight: 8,
    },
  },
  hamburgerMenuContainer: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    padding: "16px 16px",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default useStyles;
