import React, { useContext, FC } from "react";
import { AppBar } from "@material-ui/core";
import { AuthContext } from "../../Auth";
import { useHeaderStyles } from "./styles";
import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu } from "./MobileMenu";

//TODO: should probably show different menu items depending on which page you're on

export const Header: FC = () => {
  const auth = useContext(AuthContext);
  const classes = useHeaderStyles();

  return (
    <AppBar
      position="static"
      className={classes.appbar}
      color="inherit"
      elevation={0}
    >
      <DesktopMenu
        classes={classes}
        isAuthenticated={auth.isAuthenticated}
        name={auth.userInfo?.name || ""}
        logout={auth.logout}
      />
      <MobileMenu
        classes={classes}
        logout={auth.logout}
        isAuthenticated={auth.isAuthenticated}
      />
    </AppBar>
  );
};
