import React, { FC } from "react";
import { Link, LinkProps } from "@material-ui/core";
import { OpenInNew as ExternalLinkIcon } from "@material-ui/icons";

export const privacyPolicyProps = {
  description: "privacy-policy-link",
  href: "https://au.reachout.com/our-policies/privacy-policy",
};

export const contactUsProps = {
  description: "contact-us-link",
  href: "https://schools.au.reachout.com/contact-us",
};

export const termsAndConditionProps = {
  description: "terms-and-conditions-link",
  href: "https://au.reachout.com/our-policies/terms-and-conditions",
};

type Props = {
  label: string;
};

export const ExternalLink: FC<Props & LinkProps> = ({
  label,
  target = "_blank",
  ...rest
}) => (
  <Link target={target} {...rest}>
    <ExternalLinkIcon fontSize="small" /> {label}
  </Link>
);
