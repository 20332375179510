import { Theme } from "@material-ui/core";

export const deadCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const oppositeEnds = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const centeredColumn = {
  display: "flex",
  // cssinjs + typescript bug, see https://github.com/cssinjs/jss/issues/1344
  flexDirection: "column" as "column",
  alignItems: "center",
};

export const collapseOnPrint = {
  "@media print": {
    display: "block !important",
    "& > div": {
      width: "100% !important",
      maxWidth: "none !important",
    },
  },
};

// Evenly splits a container on desktop and stacks on mobile, use
// <div className={splitPanel}>
//   <div className={panelItem}>Item one</div>
//   <div className={panelItem}>Item two</div>
//   <div className={panelItem}>Item n...</div>
// </div>
export const splitPanel = (theme: Theme) => ({
  splitPanel: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  panelItem: {
    flex: 1,
  },
});
