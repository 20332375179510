import { makeStyles, StyleRules } from "@material-ui/core";
import { contentDividingBorder } from "../../styles/borders";
import { formFields, formField } from "../../styles/forms";

export const useSurveyStyles = makeStyles(
  (theme): StyleRules<string> => ({
    formSection: {
      padding: "2em",
    },
    formTitle: {
      margin: "10px",
    },
    formGroup: {
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.up("sm")]: {
        padding: "2em 3em 0 3em",
        display: "flex",
      },
    },
    innerItemCenter: {
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    },
    formFields: {
      ...formFields,
      [theme.breakpoints.up("md")]: {
        padding: "0 24px",
      },
    },
    formField: {
      ...formField,
    },
    formAlert: {
      flexWrap: "wrap",
      [theme.breakpoints.up("sm")]: {
        padding: "0 3em 0 3em",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "1em 1em 1em 1em",
      },
    },

    surveyCard: {
      // ...standardBorder,
      padding: 16,
      transition: "border-color 250ms, box-shadow 250ms",
      "&:hover": {
        borderColor: "transparent",
        boxShadow:
          "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      },
    },
    surveyCardContent: {
      justifyContent: "center",
      textAlign: "center",
    },
    surveyCardActions: {
      flexFlow: "column",
    },
    surveyCardButton: {
      textDecoration: "none",
    },
    gridSection: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: "800px",
        margin: "0 auto",
      },
    },
    surveyStep1Title: {
      margin: "10px 0 10px 0",
    },
    surveyStep2Title: {
      margin: "10px 0 10px 0",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    surveyStep1ContainerActions: {
      display: "flex",
      justifyContent: "flex-end",

      padding: 16,
    },
    surveyStep2ContainerActions: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      padding: 16,
      justifyContent: "flex-end",

      [theme.breakpoints.down("xs")]: {
        justifyContent: "space-between",
      },
    },
    surveyTemplatesContainer: {
      padding: 16,
    },
    outlined: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: "5px 15px",
      display: "flex",
      textAlign: "center",
      borderRadius: "4px",
    },
    surveyTemplateTitle: {
      fontWeight: "bold",
    },
    exampleLink: {
      zIndex: 1,
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: "left",
      padding: "12px 24px",
      borderTop: contentDividingBorder(theme),
      "& svg": {
        marginBottom: -4,
        marginRight: 4,
      },
    },
    templateButton: {
      position: "relative",
      "&:hover $selectedIndicator": {
        opacity: 1,
        background: "transparent",
      },
    },
    actions: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "space-between",
      },
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        justifyContent: "flex-end",
        "& button": {
          marginLeft: 8,
        },
      },
    },
    stepper: {
      width: 40,
      position: "absolute",
      left: "calc(50% - 20px)",
    },
    divider: {
      margin: "10px 0 10px 0",
    },
    surveyTemplateItemContainer: {
      "& p": {
        textAlign: "left",
        paddingTop: 8,
      },
      paddingBottom: 48,
      margin: 8,
      "& > $exampleLink": {
        [theme.breakpoints.down("xs")]: {
          paddingLeft: 0,
          bottom: -8,
        },
      },
    },
    surveyTemplateItem: {
      position: "relative",
    },
    selectedTemplateButton: {
      borderColor: theme.palette.primary.main,
      // NOTE: the double && here increases the priority of this rule
      // it is used to make sure the hover state doesn't override the selected state
      "&& $selectedIndicator": {
        opacity: 1,
        background: theme.palette.primary.main,
        color: "white",
      },
    },
    selectedIndicator: {
      transition: "opacity 250ms",
      opacity: 0,
      position: "absolute",
      top: 0,
      right: 0,
      padding: "8px 8px 8px 16px",
      borderBottomLeftRadius: "85%",
      color: "rgba(136, 152, 170, 0.3)",
    },
    cardTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 16,
    },
    surveyYearTitle: {
      minWidth: 50,
      marginLeft: 16,
    },
    responseGroup: {
      display: "flex",
      alignItems: "center",
      marginTop: 8,
    },
    progressBar: {
      flex: "1 1 auto",
      marginLeft: 12,
    },
    cardContent: {
      marginBottom: 8,
    },
    cardActions: {
      paddingTop: 8,
      borderTop: "1px solid rgba(136, 152, 170, 0.3)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);

export const useSurveyLinkDialogStyles = makeStyles((theme) => ({
  surveyLinkContainer: {
    border: "1px solid #8898AA",
    textAlign: "center",
    alignItems: "center",
    borderRadius: "4px",
    padding: "8px 0",
  },
  surveyLinkTitle: {
    margin: "auto",
    textAlign: "center",
  },
  surveyLinkButton: {
    backgroundColor: theme.palette.primary.main,
  },
  surveyDescription: {
    paddingBottom: "16px",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "15px 0 0 0 ",
  },
  alertText: {
    position: "absolute",
    left: 0,
    bottom: "-100px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  surveyLink: {
    wordBreak: "break-word",
    maxWidth: "459px",
    padding: "8px 0",
    margin: "auto",
    "&.copied": {
      backgroundColor: "rgba(3, 115, 207, 0.1)",
    },
  },
  alert: {
    backgroundColor: "rgba(136, 152, 170, 0.9)",
    padding: "10px 35px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.16)",
    borderRadius: "74px",
    color: "#FFFFFF",
  },
}));
