import React, { FC, useState } from "react";
import { Typography, Collapse } from "@material-ui/core";
import ResourcesGrid from "./ResourcesGrid";
import { useCollapsibleGridStyles } from "./styles";
import { ResourcesCollection } from "@reachout/student-snapshot-api-types";

const CollapsibleGrid: FC<ResourcesCollection> = ({ title, resources }) => {
  const s = useCollapsibleGridStyles();
  const [showAll, setShowAll] = useState<boolean>(false);
  const [collapseHeight, setCollapseHeight] = useState<number>(422);

  return (
    <div className="collapsible-grid-container">
      <div className={s.collapsingHeaderContainer}>
        <Typography variant="h3">{`${title} (${resources.length})`}</Typography>
        <button
          className={s.hideShowButton}
          onClick={(): void => setShowAll(!showAll)}
        >
          {showAll ? "See less" : "See all"}
        </button>
      </div>

      <Collapse in={showAll} collapsedHeight={collapseHeight}>
        <ResourcesGrid setHeight={setCollapseHeight} resources={resources} />
      </Collapse>
    </div>
  );
};

export default CollapsibleGrid;
