import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import App from "./App";
import React, { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "./styles/theme";
const muiTheme = createMuiTheme(theme);

// providers shared across both student and teacher pages
const Providers: FC = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  );
};

export default Providers;
