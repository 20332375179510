import React, { FC, useState } from "react";
import { Toolbar, Menu, MenuItem, IconButton } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";

import logo from "../../assets/image/reachout-logo.svg";

type MobileMenuProps = {
  classes: any;
  isAuthenticated: boolean;
  logout: () => void;
};

export const MobileMenu: FC<MobileMenuProps> = ({
  classes,
  logout,
  isAuthenticated,
}: MobileMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const location = useLocation();

  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };
  return (
    <Toolbar className={classes.sectionMobile}>
      <img src={logo} className={classes.logo} alt="ReachOut logo" />
      {isAuthenticated && (
        <>
          <IconButton
            aria-label="account"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem disabled={location.pathname === "/surveys"}>
              <Link to="/surveys" className={classes.menuItem}>
                Manage Surveys
              </Link>
            </MenuItem>
            <MenuItem onClick={logout}>Log out</MenuItem>
          </Menu>
        </>
      )}
    </Toolbar>
  );
};
